import { DrawnShape } from "./present-data-enums";


// classes containing all the data needed to reproducibly redraw a shape on the canvas
export default class CanvasDrawn {

  constructor(
    public x: number,
    public y: number,
    public color: string,
    public type: DrawnShape,
    public radius?: number,
    public deleteIn?: number
  ) { }

  /// Draw based on the type of shape, don't use the parent CanvasDrawn draw
  draw(context: CanvasRenderingContext2D) {
    return;
  }

  decay() {
    this.deleteIn = 0;
  }
}

export class CanvasSquare extends CanvasDrawn {
  draw(context: CanvasRenderingContext2D) {
    const sideLength = 15;
    const x1 = this.x - sideLength / 2;
    const y1 = this.y - sideLength / 2;
    context.rect(x1, y1, sideLength, sideLength);
    context.strokeStyle = this.color;
    context.stroke();
  }
}

export class CanvasCircle extends CanvasDrawn {
  draw(context: CanvasRenderingContext2D) {
    context.beginPath();
    context.arc(this.x, this.y, this.radius, 0, 2 * Math.PI);
    context.strokeStyle = this.color;
    context.stroke();
  }
}

export class CanvasLine extends CanvasDrawn {
  constructor(
    x: number,
    y: number,
    public x2: number,
    public y2: number,
    color: string,
    deleteIn?: number
  ) {
    super(x, y, color, DrawnShape.LINE, undefined, deleteIn);
  }

  draw(context: CanvasRenderingContext2D) {
    context.beginPath();
    context.moveTo(this.x, this.y);
    context.lineTo(this.x2, this.y2);
    context.strokeStyle = this.color;
    context.stroke();
  }
}

export class CanvasTriangle extends CanvasDrawn {
  draw(context: CanvasRenderingContext2D) {
    const sideLength = 15;

    const x1 = this.x;
    const y1 = this.y - sideLength / Math.sqrt(3);
    const x2 = this.x + sideLength / 2;
    const y2 = this.y + sideLength / (2 * Math.sqrt(3));
    const x3 = this.x - sideLength / 2;
    const y3 = this.y + sideLength / (2 * Math.sqrt(3));

    context.beginPath();
    context.moveTo(x1, y1);
    context.lineTo(x2, y2);
    context.lineTo(x3, y3);
    context.closePath();

    context.strokeStyle = this.color;
    context.stroke();
  }
}
