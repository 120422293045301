<div *ngIf="connectionStatus != 2" class="my-gaze__actions">

  <button *ngIf="connectionStatus != 1" mat-raised-button color="primary" (click)="connectGaze()"
    [disabled]="!userSettings.eyeTracking && !userSettings.mouseAndKeyboard && !userSettings.muse && !userSettings.screenRecording && !userSettings.faceRecording">
    Connect <mat-icon>remove_red_eye</mat-icon>
  </button>

  <button *ngIf="connectionStatus == 1" mat-raised-button color="primary">
    Connecting <mat-icon>remove_red_eye</mat-icon>
  </button>
</div>

<mat-card *ngIf="connectionStatus == 2" class="my-gaze__container">
  <span>Console application connected</span>

  <button mat-raised-button color="primary" (click)="Disconnect()">
    Disconnect <mat-icon>remove_red_eye</mat-icon>
  </button>

  <div class="my-gaze__session-name">
    <label>Insert session name: <input [(ngModel)]="sessionName" /></label>
    <small class="my-gaze__error">{{this.sessionNameError}}</small>
    <small class="my-gaze__success">{{this.sessionNameSuccess}}</small>
  </div>

  <ng-container *ngIf="userSettings.eyeTracking">
    <div *ngIf="calibrationStatus" class="my-gaze__calibration-status">
      <span>Calibration status: {{calibrationStatus}}</span>
    </div>

    <!-- <div class="my-gaze__calibration">
      <button *ngIf="calibrationStatus != 'ValidCalibration'" mat-raised-button color="primary" (click)="Calibrate()">
        Calibrate Eye tracker <mat-icon>remove_red_eye</mat-icon>
      </button>
    </div> -->

  </ng-container>
</mat-card>
