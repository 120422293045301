<vg-player *ngIf="videoUrl" (onPlayerReady)="onPlayerReady($event)">
  <ng-content style="position: absolute; height: 100%; width: 100%;" />

  <vg-controls style="z-index: 2;">
    <div class="VideoControl VideoControlLeft">
      <vg-play-pause></vg-play-pause>
      <vg-playback-button></vg-playback-button>
      <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
      <vg-mute></vg-mute>
      <vg-volume></vg-volume>
    </div>

    <div class="VideoControl VideoControlRight">
      <vg-fullscreen></vg-fullscreen>
    </div>
  </vg-controls>

  <vg-scrub-bar style="z-index: 2;">
    <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
  </vg-scrub-bar>

  <vg-overlay-play style="z-index: 1;"></vg-overlay-play>

  <video [vgMedia]="$any(media)" #media>
    <source [src]="videoUrl" type="video/mp4" />
    Your browser does not support the video tag.
  </video>

</vg-player>
