import { Component, OnInit } from '@angular/core';
import {
  CSVRecordEmotions,
  CSVRecordEyeTrackingEvent,
  CSVRecordMouseEvent,
  CSVRecordMouseMovement,
  CSVRecordMuse
} from '../shared/types/csv-model';
import { TimeStampModel } from '../shared/types/timestamp-model';
import Utils from '../utils';
import { format } from 'date-fns';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ScreenRecorderComponent } from '../data-recorder/data-recorder.component';

@Component({
  selector: 'app-timestamp-converter',
  templateUrl: './timestamp-converter.component.html',
  styleUrls: ['./timestamp-converter.component.scss']
})
export class TimestampConverterComponent implements OnInit {
  public records: any[] = [];
  public updatedRecords: any[] = [];
  public csvReader: any;
  public timestampArr: string[] = [];
  public timestampAsModelArr: TimeStampModel[] = [];
  public startingTimestamp: any;
  public startingTSAsModel: TimeStampModel;
  public timestampStringSTArr: string[] = [];
  public csvRecordsArray: any[] = [];

  public isMoraeFile = false;
  public isMuseFile = false;
  public isMouseMovementFile = false;
  public isEyeTrackingFile = false;
  public isMouseEventFile = false;
  public isEmotionFile = false;
  public isInvalidFile = false;
  public timestampExists = false;

  file: File;

  constructor(private snackbar: MatSnackBar) { }

  ngOnInit() {
  }

  uploadListener($event: any) {
    const u = new Utils();
    const files = $event.srcElement.files;

    if (u.isValidCSVFile(files[0])) {
      this.file = files[0];
      const filename = this.file.name;

      const input = $event.target;
      const reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        const csvData = reader.result;
        this.csvRecordsArray = (csvData as string).split(/\r\n|\n/);
        const headersRow = u.getHeaderArray(this.csvRecordsArray);

        // parse first row - if second cell's content is 'Recording' - Morae Data, else if it is 'angry' - Emotion Data, else if it is 'delta_TP9' - Muse data, else - invalid data
        // TODO think of a better method ???

        if (headersRow[0].substring(0, 12) === 'Elapsed Time') {
          this.isMoraeFile = true;
        } else if (headersRow[1] === 'angry') {
          this.isEmotionFile = true;
        } else if (headersRow[1] === 'delta_TP9') {
          this.isMuseFile = true;
        } else if (headersRow[1] === 'X') {
          this.isMouseMovementFile = true;
        } else if (headersRow[1] === 'ButtonName') {
          this.isMouseEventFile = true;
        } else if (headersRow[1] === '"Left eye X"') {
          this.isEyeTrackingFile = true;
        } else {
          this.isInvalidFile = true;
        }

        if (this.isMoraeFile) {
          const element = document.getElementById('morae-file-name');
          element.innerHTML = filename;
          this.getDataRecordsArrayFromCSVFile(this.csvRecordsArray, headersRow.length);
        } else {
          const element = document.getElementById('other-file-name');
          element.innerHTML = filename;
          if (this.isEmotionFile) {
            if (this.timestampExists) {
              this.records = this.getDataRecordsArrayFromCSVFile(this.csvRecordsArray, headersRow.length);
              this.timestampConversion(this.startingTimestamp, this.timestampArr);
              this.replaceTimestamps(this.csvRecordsArray, headersRow.length);

              this.saveToCSV(this.updatedRecords);
            } else {
              this.noTimestampError();
              this.isEmotionFile = false;
            }
          } else if (this.isMuseFile) {
            if (this.timestampExists) {
              this.records = this.getDataRecordsArrayFromCSVFile(this.csvRecordsArray, headersRow.length);
              this.timestampConversion(this.startingTimestamp, this.timestampArr);
              this.replaceTimestamps(this.csvRecordsArray, headersRow.length);

              this.saveToCSV(this.updatedRecords);
            } else {
              this.noTimestampError();
              this.isMuseFile = false;
            }
          } else if (this.isMouseMovementFile || this.isMouseEventFile) {
            if (this.timestampExists) {
              this.records = this.getDataRecordsArrayFromCSVFile(this.csvRecordsArray, headersRow.length);

              this.timestampConversion(this.startingTimestamp, this.timestampArr);
              this.replaceTimestamps(this.csvRecordsArray, headersRow.length);

              this.saveToCSV(this.updatedRecords);
            } else {
              this.noTimestampError();
              this.isMouseMovementFile = false;
              this.isMouseEventFile = false;
            }
          } else if (this.isEyeTrackingFile) {
            if (this.timestampExists) {
              this.records = this.getDataRecordsArrayFromCSVFile(this.csvRecordsArray, headersRow.length);

              this.timestampConversion(this.startingTimestamp, this.timestampArr);
              this.replaceTimestamps(this.csvRecordsArray, headersRow.length);

              this.saveToCSV(this.updatedRecords);
            } else {
              this.noTimestampError();
              this.isMouseMovementFile = false;
              this.isMouseEventFile = false;
            }
          } else {
            // throw error - this CSV is not valid (needs to contain Morae, Muse or emotion data)
            this.snackbar.open('Invalid file. Please upload a valid CSV file.', 'Dismiss');
          }
        }
      };

      reader.onerror = () => {
        console.log('error is occured while reading file!');
      };

    } else {
      this.snackbar.open('Please import valid .csv file.', 'Dismiss');
      this.fileReset();
    }
  }

  noTimestampError() {
    this.snackbar.open('No timestamp for recording exists. Make sure to upload CSV with Morae data to get the timestamp.', 'Dismiss');
  }

  replaceTimestamps(csvRecordsArray: any, headerLength: any) {
    for (let i = 0; i < this.records.length; i++) {
      if (this.isEmotionFile) {
        const oldCSVRecord: CSVRecordEmotions = this.records[i];
        this.updatedRecords.push([this.timestampStringSTArr[i], oldCSVRecord.angryColumn, oldCSVRecord.sadColumn, oldCSVRecord.disgustedColumn, oldCSVRecord.surprisedColumn, oldCSVRecord.happyColumn, oldCSVRecord.neutralColumn]);
      } else if (this.isMouseMovementFile) {
        const oldCSVRecord: CSVRecordMouseMovement = this.records[i];
        this.updatedRecords.push([this.timestampStringSTArr[i], oldCSVRecord.xColumn, oldCSVRecord.yColumn]);
      } else if (this.isMouseEventFile) {
        const oldCSVRecord: CSVRecordMouseEvent = this.records[i];
        this.updatedRecords.push([this.timestampStringSTArr[i], oldCSVRecord.buttonNameColumn, oldCSVRecord.stateColumn]);
      } else if (this.isEyeTrackingFile) {
        const oldCSVRecord: CSVRecordEyeTrackingEvent = this.records[i];
        this.updatedRecords.push([this.timestampStringSTArr[i], oldCSVRecord.LeftEyeXColumn, oldCSVRecord.LeftEyeYColumn, oldCSVRecord.RightEyeXColumn, oldCSVRecord.RightEyeYColumn]);
      } else if (this.isMuseFile) {
        const oldCSVRecord: CSVRecordMuse = this.records[i];
        this.updatedRecords.push([
          this.timestampStringSTArr[i],
          parseFloat(oldCSVRecord.deltaTP9Column),
          parseFloat(oldCSVRecord.deltaAF7Column),
          parseFloat(oldCSVRecord.deltaAF8Column),
          parseFloat(oldCSVRecord.deltaTP10Column),
          parseFloat(oldCSVRecord.thetaTP9Column),
          parseFloat(oldCSVRecord.thetaAF7Column),
          parseFloat(oldCSVRecord.thetaAF8Column),
          parseFloat(oldCSVRecord.thetaTP10Column),
          parseFloat(oldCSVRecord.alphaTP9Column),
          parseFloat(oldCSVRecord.alphaAF7Column),
          parseFloat(oldCSVRecord.alphaAF8Column),
          parseFloat(oldCSVRecord.alphaTP10Column),
          parseFloat(oldCSVRecord.betaTP9Column),
          parseFloat(oldCSVRecord.betaAF7Column),
          parseFloat(oldCSVRecord.betaAF8Column),
          parseFloat(oldCSVRecord.betaTP10Column),
          parseFloat(oldCSVRecord.gammaTP9Column),
          parseFloat(oldCSVRecord.gammaAF7Column),
          parseFloat(oldCSVRecord.gammaAF8Column),
          parseFloat(oldCSVRecord.gammaTP10Column)
        ]);
      }
    }
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    // Emotion
    const csvArr = [];
    for (let i = 1; i < csvRecordsArray.length; i++) {
      const currentRecord = (csvRecordsArray[i] as string).split(',');
      if (currentRecord.length === headerLength) {
        if (this.isEmotionFile) {
          const csvRecord: CSVRecordEmotions = new CSVRecordEmotions();
          csvRecord.timestampColumn = currentRecord[0].trim();
          csvRecord.angryColumn = currentRecord[1].trim();
          csvRecord.sadColumn = currentRecord[2].trim();
          csvRecord.disgustedColumn = currentRecord[3].trim();
          csvRecord.surprisedColumn = currentRecord[4].trim();
          csvRecord.happyColumn = currentRecord[5].trim();
          csvRecord.neutralColumn = currentRecord[6].trim();
          this.timestampArr.push(csvRecord.timestampColumn);
          csvArr.push(csvRecord);
        } else if (this.isMuseFile) {
          const csvRecord: CSVRecordMuse = new CSVRecordMuse();
          csvRecord.timeColumn = currentRecord[0].trim();
          csvRecord.deltaTP9Column = currentRecord[1].trim();
          csvRecord.deltaAF7Column = currentRecord[2].trim();
          csvRecord.deltaAF8Column = currentRecord[3].trim();
          csvRecord.deltaTP10Column = currentRecord[4].trim();

          csvRecord.thetaTP9Column = currentRecord[5].trim();
          csvRecord.thetaAF7Column = currentRecord[6].trim();
          csvRecord.thetaAF8Column = currentRecord[7].trim();
          csvRecord.thetaTP10Column = currentRecord[8].trim();

          csvRecord.alphaTP9Column = currentRecord[9].trim();
          csvRecord.alphaAF7Column = currentRecord[10].trim();
          csvRecord.alphaAF8Column = currentRecord[11].trim();
          csvRecord.alphaTP10Column = currentRecord[12].trim();

          csvRecord.betaTP9Column = currentRecord[13].trim();
          csvRecord.betaAF7Column = currentRecord[14].trim();
          csvRecord.betaAF8Column = currentRecord[15].trim();
          csvRecord.betaTP10Column = currentRecord[16].trim();

          csvRecord.gammaTP9Column = currentRecord[17].trim();
          csvRecord.gammaAF7Column = currentRecord[18].trim();
          csvRecord.gammaAF8Column = currentRecord[19].trim();
          csvRecord.gammaTP10Column = currentRecord[20].trim();

          this.timestampArr.push(csvRecord.timeColumn);
          csvArr.push(csvRecord);
        } else if (this.isMoraeFile) {
          const containsF9 = currentRecord.toString().includes(ScreenRecorderComponent.recordHotkey);
          if (containsF9) {
            this.startingTimestamp = currentRecord.toString().substring(0, 10);
            const element = document.getElementById('timestampText');
            element.innerHTML = 'Start time of recording: ' + this.startingTimestamp;
            this.timestampExists = true;
            this.isMoraeFile = false;
            return;
          }
        } else if (this.isMouseMovementFile) {
          const csvRecord: CSVRecordMouseMovement = new CSVRecordMouseMovement();
          const timeStampInMillis = +currentRecord[0].trim();
          csvRecord.timeColumn = format(new Date(timeStampInMillis), 'HH:mm:ss.SSS');
          csvRecord.xColumn = currentRecord[1].trim();
          csvRecord.yColumn = currentRecord[2].trim();
          this.timestampArr.push(csvRecord.timeColumn);
          csvArr.push(csvRecord);

        } else if (this.isMouseEventFile) {
          const csvRecord: CSVRecordMouseEvent = new CSVRecordMouseEvent();
          const timeStampInMillis = +currentRecord[0].trim();
          csvRecord.timeColumn = format(new Date(timeStampInMillis), 'HH:mm:ss.SSS');
          csvRecord.buttonNameColumn = currentRecord[1].trim();
          csvRecord.stateColumn = currentRecord[2].trim();
          this.timestampArr.push(csvRecord.timeColumn);

          if (csvRecord.timeColumn === '0:00:00.') {
            csvRecord.timeColumn = '0:00:00.0';
          }

          csvArr.push(csvRecord);
        } else if (this.isEyeTrackingFile) {
          const csvRecord: CSVRecordEyeTrackingEvent = new CSVRecordEyeTrackingEvent();
          csvRecord.timeColumn = currentRecord[0].trim();
          csvRecord.LeftEyeXColumn = currentRecord[1].trim();
          csvRecord.LeftEyeYColumn = currentRecord[2].trim();
          csvRecord.RightEyeXColumn = currentRecord[3].trim();
          csvRecord.RightEyeYColumn = currentRecord[4].trim();

          this.timestampArr.push(csvRecord.timeColumn);
          csvArr.push(csvRecord);

        }
      }
    }
    return csvArr;
  }

  timestampConversion(startTimeOfRecording: any, timeStampArr: string[]) {
    this.parseStartingTimestampAsModel(startTimeOfRecording);
    this.parseTimestampsAsModels(timeStampArr);
    this.standardizeTimestamps(this.startingTSAsModel, this.timestampAsModelArr);
  }

  parseStartingTimestampAsModel(startTimeOfRecording: any) {
    this.startingTSAsModel = new TimeStampModel();
    const startTimeST = startTimeOfRecording.split(':');
    const startTimeSecondsSplitST = startTimeST[2].split('.');
    this.startingTSAsModel.hours = startTimeST[0];
    this.startingTSAsModel.minutes = startTimeST[1];
    this.startingTSAsModel.seconds = startTimeSecondsSplitST[0];
    this.startingTSAsModel.millis = startTimeSecondsSplitST[1];
  }

  parseTimestampsAsModels(timestampArr: any) {
    for (let i = 0; i < timestampArr.length; i++) {
      const timestampAsModel = new TimeStampModel();
      const timestampNS = timestampArr[i].split(':');
      timestampAsModel.hours = timestampNS[0];
      timestampAsModel.minutes = timestampNS[1];
      // Round up milliseconds up to standardize with Morae timetamps
      // const roundedTimestampSeconds = timestampNS[3].toFixed(2);
      // const timestampSecondsSplitNS = roundedTimestampSeconds[2].split('.');
      const timestampSecondsSplitNS = timestampNS[2].split('.');
      timestampAsModel.seconds = timestampSecondsSplitNS[0];
      timestampAsModel.millis = parseFloat(timestampSecondsSplitNS[1]);
      this.timestampAsModelArr.push(timestampAsModel);
    }
  }

  standardizeTimestamps(startingTSAsModel: TimeStampModel, timestampAsModelArr: TimeStampModel[]) {
    const timestampsAsMillisArr = [];
    const timestampsAsSTMillisArr = [];
    // convert starting timestamp into milliseconds
    const millis = startingTSAsModel.millis * 10;
    const secondsAsMillis = startingTSAsModel.seconds * 1000;
    const minutesAsMillis = startingTSAsModel.minutes * 60000;
    const hoursAsMillis = startingTSAsModel.hours * 3600000;
    const startingTimeInMillis = hoursAsMillis + minutesAsMillis + secondsAsMillis + millis;

    // convert timestamps in array into milliseconds
    this.timestampAsModelArr.forEach(element => {
      const millisTS = element.millis;
      const secondsAsMillisTS = element.seconds * 1000;
      const minutesAsMillisTS = element.minutes * 60000;
      const hoursAsMillisTS = element.hours * 3600000;
      const timestampAsMillis = hoursAsMillisTS + minutesAsMillisTS + secondsAsMillisTS + millisTS;
      timestampsAsMillisArr.push(timestampAsMillis);
    });

    // calculate standardized timestamps in millis
    for (let i = 0; i < timestampsAsMillisArr.length; i++) {
      if (i > 0) {
        // const timestampAsSTMillis = (startingTimeInMillis * timestampsAsMillisArr[i]) / timestampsAsMillisArr[0];
        const timestampAsSTMillis = (timestampsAsMillisArr[i] - timestampsAsMillisArr[0]) + startingTimeInMillis;
        timestampsAsSTMillisArr.push(timestampAsSTMillis);
      } else {
        timestampsAsSTMillisArr.push(startingTimeInMillis);
      }
    }

    // create standardized timestamp string
    timestampsAsSTMillisArr.forEach(element => {
      let millisReminder = element;
      let hourPortion = '0';
      let minutePortion = '0';
      let secondPortion = '0';
      let millisPortion = '0';
      if (millisReminder / 3600000 >= 1) {
        hourPortion = (millisReminder / 3600000).toString().split('.')[0];
        millisReminder = millisReminder - parseInt(hourPortion) * 3600000;
      }
      if (millisReminder / 60000 >= 1) {
        minutePortion = (millisReminder / 60000).toString().split('.')[0];
        millisReminder = millisReminder - parseInt(minutePortion) * 60000;
      }
      if (parseInt(minutePortion) < 10) {
        minutePortion = '0' + minutePortion;
      }

      if (millisReminder / 1000 >= 1) {
        secondPortion = (millisReminder / 1000).toString().split('.')[0];
        millisReminder = millisReminder - parseInt(secondPortion) * 1000;
      }
      if (parseInt(secondPortion) < 10) {
        secondPortion = '0' + secondPortion;
      }

      // round millis up or down so there's always 0 at end
      if (millisReminder % 10 !== 0) {
        // const millisUnTrimmed = Math.round(millisReminder / 10).toString();
        millisPortion = Math.round(millisReminder / 10).toString();
        // millisPortion = millisUnTrimmed.substring(0, millisUnTrimmed.length);
      } else {
        const millisReminderAsString = millisReminder.toString();
        millisPortion = millisReminderAsString.substring(0, millisReminderAsString.length - 1);
      }
      if (millisPortion === '') {
        millisPortion = '0';
      }
      // millisPortion = millisReminder;
      const timestampStringST = hourPortion + ':' + minutePortion + ':' + secondPortion + '.' + millisPortion;

      this.timestampStringSTArr.push(timestampStringST);
    });
    return this.timestampStringSTArr;
  }

  fileReset() {
    this.csvReader.nativeElement.value = '';
    this.records = [];
  }

  saveToCSV(updatedRecords: any) {
    const a = document.createElement('a');
    let headers = [];
    if (this.isEmotionFile) {
      headers = ['time', 'angry', 'sad', 'disgusted', 'surprised', 'happy', 'neutral'];
      a.download = 'emotions_synced.csv';
      this.isEmotionFile = false;
    } else if (this.isMouseMovementFile) {
      headers = ['Timestamp', 'X', 'Y'];
      a.download = 'mouse_movement_synced.csv';
      this.isMouseMovementFile = false;
    } else if (this.isMouseEventFile) {
      headers = ['Timestamp', 'ButtonName', 'State'];
      a.download = 'mouse_event_synced.csv';
      this.isMouseEventFile = false;
    } else if (this.isEyeTrackingFile) {
      headers = ['Timestamp', 'LeftEyeX', 'LeftEyeY', 'RightEyeX', 'RightEyeY'];
      a.download = 'eye_tracking_synced.csv';
      this.isEyeTrackingFile = false;
    } else if (this.isMuseFile) {
      headers = ['time', 'delta_TP9', 'delta_AF7', 'delta_AF8', 'delta_TP10', 'theta_TP9', 'theta_AF7', 'theta_AF8', 'theta_TP10', 'alpha_TP9', 'alpha_AF7', 'alpha_AF8', 'alpha_TP10', 'beta_TP9', 'beta_AF7', 'beta_AF8', 'beta_TP10', 'gamma_TP9', 'gamma_AF7', 'gamma_AF8', 'gamma_TP10'];
      a.download = 'muse_synced.csv';
      this.isMuseFile = false;
    }
    // const headers = ['time', 'angry', 'sad', 'disgusted', 'surprised', 'happy', 'neutral'];
    const csvData = headers + '\n' + updatedRecords.map(item => item.join(',')).join('\n');
    const file = new Blob([csvData], { type: 'text/csv' });
    a.href = URL.createObjectURL(file);
    document.body.appendChild(a);
    // a.download = 'emotions_synced.csv';
    a.click();
    document.body.removeChild(a);
    this.updatedRecords = [];
    this.timestampArr = [];
    this.timestampAsModelArr = [];
    this.timestampStringSTArr = [];
  }

}
