import { Component, Input } from '@angular/core';
import { RequestFile } from '../file-input/request-files';
import { FileDataType } from './recorded-data-enums';
import inputFilePresets from './request-files-presets';

@Component({
  selector: 'app-recorded-data-input',
  templateUrl: './recorded-data-input.component.html',
  styleUrl: './recorded-data-input.component.scss'
})
export class RecordedDataInputComponent {

  // requestFiles is a enum flag, essentially a fancy list of booleans
  private _requestFilesFlag = FileDataType.None;

  @Input()
  get requestFilesFlag(): RecordedDataInputComponent['_requestFilesFlag'] {
    return this._requestFilesFlag;
  }
  set requestFilesFlag(param: RecordedDataInputComponent['_requestFilesFlag']) {
    this.UpdateFileInputsToRequest(param);
    this._requestFilesFlag = param;
  }

  filesToShowInputs: RequestFile[] = [];


  UpdateFileInputsToRequest(flags: RecordedDataInputComponent['_requestFilesFlag']) {
    if (this._requestFilesFlag === flags) return;
    this.filesToShowInputs = inputFilePresets.filter((preset) => {
      return flags & preset.flag;
    });
  }

}
