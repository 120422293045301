import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatAnchor } from "@angular/material/button";
import { MatButtonModule } from "@angular/material/button";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatTabsModule, MatTabNav } from "@angular/material/tabs";
import { VgCoreModule } from "@videogular/ngx-videogular/core";
import { VgControlsModule } from "@videogular/ngx-videogular/controls";
import { VgOverlayPlayModule } from "@videogular/ngx-videogular/overlay-play";
import { VgBufferingModule } from "@videogular/ngx-videogular/buffering";
import { TimeSeriesComponent } from "./time-series/time-series.component";
import { TimeSeriesAbsoluteValuesComponent } from "./time-series-absolute-values/time-series-absolute-values.component";
import { EEGRecorder } from "./recorder/recorder.component";
import { WebcamEmotionRecognitionComponent } from "./webcam-emotion-recognition/webcam-emotion-recognition.component";
import { TimestampConverterComponent } from "./timestamp-converter/timestamp-converter.component";
import { RecordingComponent } from "./recording/recording.component";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { CleanDataComponent } from "./clean-data/clean-data.component";
import { ProcessMuseFileComponent } from "./process-muse-file/process-muse-file.component";
import { ProcessProjectDataFileComponent } from "./process-project-data-file/process-project-data-file.component";
import { ProcessEmotionsFileComponent } from "./process-emotions-file/process-emotions-file.component";
import { SensorVisualizerComponent } from "./sensor-visualizer/sensor-visualizer.component";
import { ScreenRecorderComponent } from "./data-recorder/data-recorder.component";
import { AppConsoleConnector } from "./console-app-connector/console-app-connector.component";
import { FormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { PresentDataComponent } from "./present-data/present-data.component";
import { SteppableVideoComponent } from './steppable-video/steppable-video.component';
import { RedrawingCanvasComponent } from './redrawing-canvas/redrawing-canvas.component';
import { TrackerDataDisplayComponent } from './tracker-data-display/tracker-data-display.component';
import { FileInputComponent } from './file-input/file-input.component';
import { RecordedDataInputComponent } from './recorded-data-input/recorded-data-input.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    TimeSeriesComponent,
    TimeSeriesAbsoluteValuesComponent,
    EEGRecorder,
    AppConsoleConnector,
    WebcamEmotionRecognitionComponent,
    TimestampConverterComponent,
    RecordingComponent,
    CleanDataComponent,
    ProcessMuseFileComponent,
    PresentDataComponent,
    ProcessProjectDataFileComponent,
    ProcessEmotionsFileComponent,
    SensorVisualizerComponent,
    ScreenRecorderComponent,
    AppConsoleConnector,
    SteppableVideoComponent,
    RedrawingCanvasComponent,
    TrackerDataDisplayComponent,
    FileInputComponent,
    RecordedDataInputComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatAnchor,
    MatButtonModule,
    MatSnackBarModule,
    MatToolbarModule,
    AppRoutingModule,
    MatCardModule,
    MatIconModule,
    MatTabsModule,
    FormsModule,
    MatCheckboxModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule
  ],
  providers: [
    MatTabNav,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
