<mat-toolbar color="primary">
  <span>NeuroUX</span>
</mat-toolbar>
<div style="padding: 5px">
  <nav mat-tab-nav-bar class="nav-bar" backgroundColor="accent" [tabPanel]="tabPanel">
    <a mat-tab-link *ngFor="let routeLink of routeLinks; let i = index" [routerLink]="routeLink.link"
      routerLinkActive="mat-tab-link-active" #rla="routerLinkActive" [active]="activeLinkIndex === i">{{ routeLink.label
      }}</a>
  </nav>
  <router-outlet #tabPanel></router-outlet>
</div>
<app-footer />
