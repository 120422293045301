import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CSVRecordEyeTrackingEvent, CSVRecordMouseEvent } from '../shared/types/csv-model';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { VideoService } from '../services/video.service';

@Component({
  selector: 'app-steppable-video',
  templateUrl: './steppable-video.component.html',
  styleUrl: './steppable-video.component.scss',
  providers: [VideoService]
})

export class SteppableVideoComponent {
  @Input({ required: true }) videoUrl: string | ArrayBuffer;
  @Input() width?: number;
  @Input() height?: number;
  /// Things that we show on the canvas overlay based on the video time, and can step through
  @Input() mouseEvents?: CSVRecordMouseEvent[];
  @Input() eyeEvents?: CSVRecordEyeTrackingEvent[];

  @Output() playerReady = new EventEmitter<VgApiService>();

  videoAPI?: VgApiService;

  constructor(private videoService: VideoService) { }

  onPlayerReady(api: VgApiService) {
    this.videoAPI = api;
    this.playerReady.emit(api);
    this.videoService.setApi(api);
  }

  seekToTime(time: number) {
    this.videoAPI.currentTime = time;
  }

  startVideoTimer() { }
}
