import { Component, OnInit, Input, ElementRef, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';
import { EEGSample } from 'muse-js';

import { powerByBand, epoch, fft } from '@neurosity/pipes';
import { SmoothieChart, TimeSeries } from 'smoothie';
import { ChartService } from '../services/chart.service';

@Component({
  selector: 'app-time-series-absolute-values',
  templateUrl: './time-series-absolute-values.component.html',
  styleUrls: ['./time-series-absolute-values.component.scss']
})
export class TimeSeriesAbsoluteValuesComponent implements OnInit, AfterViewInit {

  @Input() data: Observable<EEGSample>;

  readonly options;
  readonly colors: ReturnType<typeof ChartService.prototype.getColors>;

  readonly channels = 5;
  readonly channelNames = ['alpha', 'beta', 'delta', 'gamma', 'theta'];
  // readonly charts = Array(this.channels).fill(0).map(() => new SmoothieChart())

  readonly chart: SmoothieChart;
  private readonly lines = Array(this.channels).fill(0).map(() => new TimeSeries());

  constructor(private view: ElementRef, private chartService: ChartService) {
    this.chartService = chartService;
    this.colors = this.chartService.getColors();
    this.options = this.chartService.getChartSmoothieDefaults({
      millisPerPixel: 8,
      maxValue: 5,
      minValue: 0
    });
    this.chart = new SmoothieChart(this.options);
  }

  ngOnInit() {
    this.addTimeSeries();

    this.data.pipe(
      epoch({ duration: 256, interval: 50 }),
      fft({ bins: 256 }),
      powerByBand(),
      // map(buffer => buffer.alpha)
    );
    // .subscribe((buffer) => {

    //   this.draw(buffer.alpha);
    //   console.log(buffer);
    // });
  }

  addTimeSeries() {
    this.lines.forEach((line: TimeSeries, index) => {
      this.chart.addTimeSeries(line, {
        lineWidth: 2,
        strokeStyle: this.colors[index].borderColor
      });
    });
  }

  ngAfterViewInit() {
    const channels = this.view.nativeElement.querySelectorAll('canvas');
    this.chart.streamTo(channels[0]);
    // console.log(channels);
    // this.chart.streamTo
  }

  draw(buffer) {
    this.lines.forEach((line: TimeSeries, index) => {
      line.append(Date.now(), buffer[index]);
    });
  }

}
