import { format } from 'date-fns';

export default class Utils {
  isValidCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  getHeaderArray(csvRecordsArr: any) {
    const headers = (csvRecordsArr[0] as string).split(',');
    const headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  getHeaderArrayForTSV(csvRecordsArr: any) {
    const headers = (csvRecordsArr[0] as string).split('\t');
    const headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }
}

export function createTimestamp(startEpoch: number): string {
  const date = Date.now();
  const timePassed = new Date(date - startEpoch);
  const hours = `${timePassed.getUTCHours()}`.padStart(2, '0');
  const minutes = `${timePassed.getUTCMinutes()}`.padStart(2, '0');
  const seconds = `${timePassed.getUTCSeconds()}`.padStart(2, '0');
  const milliseconds = `${timePassed.getUTCMilliseconds()}`.padStart(3, '0');
  return `${hours}:${minutes}:${seconds}.${milliseconds}`;
}

export const formatTimeUTC = (milliseconds: number): string => {
  const localDate = new Date(milliseconds);
  const utcDate = new Date(
    localDate.getUTCFullYear(),
    localDate.getUTCMonth(),
    localDate.getUTCDate(),
    localDate.getUTCHours(),
    localDate.getUTCMinutes(),
    localDate.getUTCSeconds(),
    localDate.getUTCMilliseconds()
  );
  return format(utcDate, 'H:mm:ss.SS');
};


export function formatTimeWithWords(milliseconds: number): string {
  const units = [
    { name: 'hour', value: 3600000 },
    { name: 'minute', value: 60000 },
    { name: 'second', value: 1000 },
    { name: 'millisecond', value: 1 },
  ];
  let remainingMilliseconds = milliseconds;
  let result = '';
  for (const unit of units) {
    const value = Math.floor(remainingMilliseconds / unit.value);
    if (value > 0) {
      result += `${value} ${unit.name}${value > 1 ? 's' : ''} `;
      remainingMilliseconds -= value * unit.value;
    }
  }
  return result.trim();
}
