import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TimeStampModel } from '../shared/types/timestamp-model';
import Utils from '../utils';
import { CSVProjectData } from '../shared/types/csv-model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-process-project-data-file',
  templateUrl: './process-project-data-file.component.html',
  styleUrls: ['./process-project-data-file.component.scss']
})
export class ProcessProjectDataFileComponent implements OnInit {

  public records: any[] = [];
  public updatedRecords: any[] = [];
  public csvReader: any;
  public timestampArr: string[] = [];
  public timestampAsModelArr: TimeStampModel[] = [];
  public startingTimestamp: any;
  public startingTSAsModel: TimeStampModel;
  public timestampStringSTArr: string[] = [];
  public csvRecordsArray: any[] = [];
  public gluedTSVArray: any[] = [];


  public isMoraeFile = false;
  public isMuseFile = false;
  public isEmotionFile = false;
  public isInvalidFile = false;
  public timestampExists = false;
  timeStampsAsSeconds: number;
  timeStampsAsSecondsArr: any[];

  file: File;

  @Output() timesEvent = new EventEmitter<TaskTimes>();

  constructor(private snackbar: MatSnackBar) { }

  ngOnInit() {
  }

  uploadListener($event: any) {
    const u = new Utils();
    const files = $event.srcElement.files;

    if (u.isValidCSVFile(files[0])) {
      this.file = files[0];

      const input = $event.target;
      const reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        const csvData = reader.result;
        this.csvRecordsArray = (csvData as string).split(/\r\n|\n/);
        const headersRow = u.getHeaderArrayForTSV(this.csvRecordsArray);
        if (headersRow[0] === 'Elapsed Time') {
          this.isMoraeFile = true;
        } else {
          this.isInvalidFile = true;
        }

        if (this.isMoraeFile) {
          this.records = this.getDataRecordsArrayFromCSVFile(this.csvRecordsArray, headersRow.length);
          this.parseTimestampsAsModels(this.timestampArr);
          this.convertTimestampsToSeconds();

          this.timesEvent.emit(this.findMarkers());

        } else {
          this.snackbar.open('Uploaded file is invalid.', 'Dismiss');
        }
      };
      reader.onerror = () => {
        console.log('error is occured while reading file!');
      };
    } else {
      this.snackbar.open('Please import valid .csv file.', 'Dismiss');
      this.fileReset();
    }
  }

  findMarkers(): TaskTimes {
    const tasksStartTimeArr = [];
    const tasksEndTimeArr = [];
    let taskTime = new TaskTime();
    const taskTimes = new TaskTimes();
    const taskTimesArr = [];
    const baseline = new TaskTime();
    for (let i = 0; i < this.records.length; i++) {
      const line = this.records[i];

      if (line.event === 'Marker') {
        if (line.details === 'K') {
          // baseline start marker
          baseline.startTime = line.timeInSeconds;
        } else if (line.details === 'L') {
          // baseline end marker
          baseline.endTime = line.timeInSeconds;
        } else if (line.details === 'A') {
          // task start marker
          tasksStartTimeArr.push(line.timeInSeconds);
        } else if (line.details === 'B') {
          // task end marker
          tasksEndTimeArr.push(line.timeInSeconds);
        }
      }
    }

    taskTimes.baseline = baseline;

    for (let i = 0; i < tasksStartTimeArr.length; i++) {
      if (tasksStartTimeArr.length === tasksEndTimeArr.length) {
        taskTime.startTime = tasksStartTimeArr[i];
        taskTime.endTime = tasksEndTimeArr[i];
        taskTimesArr.push(taskTime);
        taskTime = new TaskTime();
      } else {
        console.log('tasksStartTimeArr and tasksEndTimeArr have different lengths');
        this.snackbar.open("Error reading project data. Please make sure there is one start (A) and one end (B) marker for each task.", "Dismiss");
        return;
      }
    }

    taskTimes.taskTimesArr = taskTimesArr;
    return taskTimes;
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    const csvArr = [];
    let previousLine = (csvRecordsArray[1] as string).split('\t');

    for (let i = 2; i < csvRecordsArray.length; i++) {
      // Project Data is actually TSV (tab separated value) not CSV (comma separated value) file, therefor split by tabs
      const currentLine = (csvRecordsArray[i] as string).split('\t');

      if (i === csvRecordsArray.length - 1) {
        this.gluedTSVArray.push(previousLine);
      } else {
        // check with regex if current line's first element is timestamp
        if (/^([0-9]{1}:[0-9]{2}:[0-9]{2}.[0-9]{2})$/.test(currentLine[0])) {
          this.gluedTSVArray.push(previousLine);
          previousLine = currentLine;
        } else {
          const firstPart = previousLine;
          const middlePart = previousLine[previousLine.length - 1].concat(' ' + currentLine[0]);
          firstPart.pop();

          const lastPart = currentLine;
          lastPart.shift();

          let gluedLine = [];
          gluedLine = firstPart.concat(middlePart).concat(lastPart);

          previousLine = gluedLine;
        }
      }
    }

    for (let i = 0; i < this.gluedTSVArray.length; i++) {
      const csvRecord: CSVProjectData = new CSVProjectData();
      csvRecord.elapsedTime = this.gluedTSVArray[i][0];
      this.timestampArr.push(csvRecord.elapsedTime);
      csvRecord.recording = this.gluedTSVArray[i][1];
      csvRecord.task = this.gluedTSVArray[i][2];
      csvRecord.event = this.gluedTSVArray[i][3];
      csvRecord.details = this.gluedTSVArray[i][4];
      csvRecord.application = this.gluedTSVArray[i][5];
      csvRecord.owner = this.gluedTSVArray[i][6];
      csvRecord.notes = this.gluedTSVArray[i][7];
      csvRecord.url = this.gluedTSVArray[i][8];
      csvRecord.pageTitle = this.gluedTSVArray[i][9];
      csvRecord.title = this.gluedTSVArray[i][10];
      csvRecord.score = this.gluedTSVArray[i][11];

      csvArr.push(csvRecord);
    }

    return csvArr;
  }

  parseTimestampsAsModels(timestampArr: any) {
    for (let i = 0; i < timestampArr.length; i++) {
      const timestampAsModel = new TimeStampModel();
      const timestampNS = timestampArr[i].split(':');
      timestampAsModel.hours = timestampNS[0];
      timestampAsModel.minutes = timestampNS[1];
      const timestampSecondsSplitNS = timestampNS[2].split('.');
      timestampAsModel.seconds = timestampSecondsSplitNS[0];
      timestampAsModel.millis = parseFloat(timestampSecondsSplitNS[1]);
      this.timestampAsModelArr.push(timestampAsModel);
    }
  }

  convertTimestampsToSeconds() {
    for (let i = 0; i < this.timestampAsModelArr.length; i++) {
      const element = this.timestampAsModelArr[i];
      this.timeStampsAsSeconds = parseInt(element.seconds.toString()) + parseInt(element.minutes.toString()) * 60 + parseInt(element.hours.toString()) * 3600;
      this.records[i].timeInSeconds = this.timeStampsAsSeconds;
    }
  }

  fileReset() {
    this.csvReader.nativeElement.value = '';
    this.records = [];
  }
}

export class TaskTime {
  public startTime: any;
  public endTime: any;
}

export class TaskTimes {
  public baseline: TaskTime;
  public taskTimesArr: TaskTime[];
}
