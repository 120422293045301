import { FileDataType } from './recorded-data-enums';
import { RequestFile } from '../file-input/request-files';



export const projectData: RequestFile = {
  defaultFileName: 'project_data',
  displayName: 'Project Data',
  fileType: '.csv',
  flag: FileDataType.ProjectData,
};

export const screenFile: RequestFile = {
  defaultFileName: 'ekraan',
  displayName: 'Screen Video File',
  fileType: '.webm',
  flag: FileDataType.ScreenVideo,
};

export const cameraFile: RequestFile = {
  defaultFileName: 'camera',
  displayName: 'Camera Video File',
  fileType: '.webm',
  flag: FileDataType.CameraVideo,
};

export const mouseMovementsFile: RequestFile = {
  defaultFileName: 'mouse_movement',
  displayName: 'Mouse Data',
  fileType: '.csv',
  flag: FileDataType.MouseMovements,
};

export const mouseMovementsFileSynced: RequestFile = {
  defaultFileName: 'mouse_movement_synced',
  displayName: 'Mouse Data Synced',
  fileType: '.csv',
  flag: FileDataType.MouseMovementsSynced,
};

export const mouseEvents: RequestFile = {
  defaultFileName: 'mouse_synced',
  displayName: 'Mouse Data Synced',
  fileType: '.csv',
  flag: FileDataType.MouseMovementsSynced,
};

export const eyeTrackingFile: RequestFile = {
  defaultFileName: 'eye_tracking',
  displayName: 'Eye Tracking Data',
  fileType: '.csv',
  flag: FileDataType.EyeTracking,
};

export const eyeTrackingFileSynced: RequestFile = {
  defaultFileName: 'eye_tracking_synced',
  displayName: 'Eye Tracking Data Synced',
  fileType: '.csv',
  flag: FileDataType.EyeTrackingSynced,
};

const inputFilePresets = [
  projectData,
  mouseMovementsFile,
  mouseMovementsFileSynced,
  mouseEvents,
  eyeTrackingFile,
  eyeTrackingFileSynced,
  screenFile,
  cameraFile,
];

export default inputFilePresets;
