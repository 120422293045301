import { Component, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { tap, throttleTime } from 'rxjs/operators';
import { createTimestamp } from '../utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommunicationService } from '../services/communication.service';
import { RecordingUserConfig } from '../shared/types/user-config';

@Component({
  selector: 'screen-webcam-recorder',
  templateUrl: './data-recorder.component.html',
  styleUrls: ['./data-recorder.component.scss'],
})
export class ScreenRecorderComponent implements OnInit, OnDestroy {
  private screenVideo: MediaStream;
  private webcamVideo: MediaStream;
  private webcamRecorder: MediaRecorder;
  private screenRecorder: MediaRecorder;
  private webcamData: Blob[] = [];
  private screenData: Blob[] = [];
  private logData = '';
  private scrollObs: Observable<any>;
  private subscriptions: Subscription;
  private scrollX = window.scrollX;
  private scrollY = window.scrollY;
  private startEpoch = 0;
  private userSettings: RecordingUserConfig;

  recordingVideo = false;

  static chooseWindowHotkey = 'F8';
  static recordHotkey = 'F9';

  constructor(private comData: CommunicationService, private snackbar: MatSnackBar) {
  }

  ngOnInit() {
    // Ask user for access to webcam and microphone
    navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    document.addEventListener('keydown', this.keydownHandler);
    // Create a throttled scroll listener
    this.scrollObs = fromEvent(window, 'scroll').pipe(
      throttleTime(300),
      tap(this.scrollHandler)
    );

    const userSettingsSub = this.comData.recordingSettings.subscribe((settings) => {
      this.userSettings = settings;
    });
    const isRecordingSub = this.comData.isRecording.subscribe((isRecording) => {
      if (!isRecording && this.recordingVideo) {
        this.stopRecording();
      }
    });

    this.subscriptions = new Subscription();
    this.subscriptions.add(userSettingsSub);
    this.subscriptions.add(isRecordingSub);
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.keydownHandler);
    this.stopRecording();
  }

  startRecording() {
    if (this.recordingVideo || !this.comData.changeIsRecording(true)) return;

    // stop the recording if any of the streams stop

    this.startEpoch = Date.now();
    this.logData = `${createTimestamp(this.startEpoch)} Recording started\n`;
    this.recordingVideo = true;

    if (this.webcamVideo) {
      this.webcamRecorder = new MediaRecorder(this.webcamVideo);
      this.webcamRecorder.start();
      this.webcamRecorder.ondataavailable = (e) => this.webcamData.push(e.data);

      this.webcamRecorder.onstop = () => {
        this.downloadVideo(this.webcamData, 'camera');
        // onstop runs also when the user stops allowing camera or screen streaming
        if (!this.webcamVideo.active) {
          this.webcamVideo = null;
        }
        // if onstop runs via stopRecording, this.RecordingVideo will be false by now
        if (!this.recordingVideo) return;
        this.stopRecording();
      };
    }

    if (this.screenVideo) {
      this.screenRecorder = new MediaRecorder(this.screenVideo);
      this.screenRecorder.start();
      this.screenRecorder.ondataavailable = (e) => this.screenData.push(e.data);

      this.screenRecorder.onstop = () => {
        this.downloadVideo(this.screenData, 'screen');
        this.downloadTimestamps();
        if (!this.screenVideo.active) {
          this.screenVideo = null;
        }
      };
    };

    document.addEventListener('keypress', this.keypressHandler);
    document.addEventListener('mousedown', this.clickHandler);
    const scrollSub = this.scrollObs.subscribe();
    if (!this.subscriptions) {
      this.subscriptions = new Subscription();
    }
    this.subscriptions.add(scrollSub);
  }

  stopRecording() {
    if (!this.recordingVideo) return;
    this.recordingVideo = false;
    this.comData.changeIsRecording(false);
    this.logData += `${createTimestamp(this.startEpoch)} Recording has stopped\n`;
    this.webcamRecorder?.stop();
    this.screenRecorder?.stop();
    document.removeEventListener('keypress', this.keypressHandler);
    document.removeEventListener('mousedown', this.clickHandler);

    this.subscriptions?.unsubscribe();
    this.subscriptions = null;
  }

  downloadVideo(data: Blob[], name: string) {
    const blob = new Blob(data);
    const url = URL.createObjectURL(blob);
    const downloadLink: HTMLAnchorElement = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = `${name}.webm`;
    downloadLink.click();
    window.URL.revokeObjectURL(url);
  }

  downloadTimestamps() {
    const downloadLink: HTMLAnchorElement = document.createElement('a');
    downloadLink.href = `data:text/plain;charset=utf-8,${encodeURIComponent(
      this.logData
    )}`;
    downloadLink.download = `log.txt`;
    downloadLink.click();
    this.logData = '';
  }

  private keydownHandler = async (event: KeyboardEvent): Promise<void> => {
    // this has to run before we start recording, so we run it on both hotkeys
    // also ensure that when pressing the recording button, only request access to recordings if we don't have them already
    if (
      event.key === ScreenRecorderComponent.chooseWindowHotkey
      || (event.key === ScreenRecorderComponent.recordHotkey && !this.hasRequiredPermissions())
    ) {
      if (this.userSettings.screenRecording) {
        try {
          const displayMedia = await navigator.mediaDevices.getDisplayMedia({ video: { displaySurface: 'screen' } });
          if (displayMedia) {
            this.screenVideo = displayMedia;
          }
        } catch (error: unknown) {
          console.error("Screen Recorder error: ", error);
        }
      }

      if (this.userSettings.faceRecording) {
        try {
          const userMedia = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
          if (userMedia) {
            this.webcamVideo = userMedia;
          }
        } catch (error: unknown) {
          console.error("Webcam recorder error: ", error);
        }
      }

      if (!this.webcamVideo || !this.screenVideo) {
        let missing = !this.webcamVideo ? "webcam" : "screen";
        if (this.webcamVideo && !this.screenVideo) {
          missing += ' and screen';
        }
        this.snackbar.open("Please allow access to " + missing, "Dismiss");
      }

    }
    if (event.key === ScreenRecorderComponent.recordHotkey) {
      if (this.recordingVideo) {
        this.stopRecording();
      } else if (this.hasRequiredPermissions()) {
        this.startRecording();
        // this will send to the other components so that they also start recording
      } else {
        this.snackbar.open(`Failed to ${this.recordingVideo ? "pause" : "start"} recording`, "Dismiss");
      }
    }
  };

  private hasRequiredPermissions() {
    if (!this.userSettings.faceRecording && !this.userSettings.screenRecording) {
      return true;
    }
    return this.userSettings.faceRecording && !!this.webcamVideo || this.userSettings.screenRecording && !!this.screenVideo;
  }

  private keypressHandler = (e: KeyboardEvent) => {
    const key = e.key === ' ' ? 'empty space' : e.key;
    this.logData += `${createTimestamp(this.startEpoch)} Pressed ${key}\n`;
  };

  private clickHandler = (e: MouseEvent) => {
    switch (e.button) {
      case 0:
        this.logData += `${createTimestamp(this.startEpoch)} left click x:${e.screenX
          }, y:${e.screenY}\n`;
        break;
      case 1:
        this.logData += `${createTimestamp(this.startEpoch)} middle click x:${e.screenX
          }, y:${e.screenY}\n`;
        break;
      case 2:
        this.logData += `${createTimestamp(this.startEpoch)} right click x:${e.screenX
          }, y:${e.screenY}\n`;
        break;
    }
  };

  private scrollHandler = () => {
    this.logData += `${createTimestamp(
      this.startEpoch
    )} scrolled x:${this.scrollX.toFixed(2)}, y:${this.scrollY.toFixed(
      2
    )} -> x:${window.scrollX.toFixed(2)}, y:${window.scrollY.toFixed(2)}\n`;
    this.scrollX = window.scrollX;
    this.scrollY = window.scrollY;
  };
}
