<mat-toolbar class="footer" style="z-index: 10;">
  <div class="container">
    <p style="font-size: 1rem">
      <a class="mat-tab-link" href="https://neuroux.twn.zone">Visit NeuroUX Demo</a>
      <a class="mat-tab-link" [href]="getConsoleAppURL()">
        Download Console Application
      </a>
    </p>
  </div>
  <mat-toolbar>
  </mat-toolbar>
</mat-toolbar>
