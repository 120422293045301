

// bitfield so we don't use too many booleans
export enum FileDataType {
  None = 0,
  ProjectData = 1 << 0,
  MouseEventsSynced = 1 << 1,
  MouseEvents = 1 << 2,
  MouseMovements = 1 << 3,
  MouseMovementsSynced = 1 << 4,
  EyeTracking = 1 << 5,
  EyeTrackingSynced = 1 << 6,
  ScreenVideo = 1 << 7,
  CameraVideo = 1 << 8,
  All = ~(0 << 9)
}
