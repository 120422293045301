import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import Utils from "../utils";
import { CSVRecordEyeTrackingEvent, CSVRecordMouseEvent, CSVRecordMouseMovement } from "../shared/types/csv-model";
import { parse } from "date-fns";
import { MatSnackBar } from '@angular/material/snack-bar';
import { VgApiService, VgStates } from '@videogular/ngx-videogular/core';
import { FileDataType } from '../recorded-data-input/recorded-data-enums';
import { CommunicationService } from '../services/communication.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-present-data',
  templateUrl: './present-data.component.html',
  styleUrls: ['./present-data.component.scss']
})

export class PresentDataComponent implements OnInit, OnDestroy {
  frameWidth = 1280;
  frameHeight = 720;
  // sharedPanelHeight = 160;
  // magicLostPixels = 206;

  // seconds of time to show a long seen drawn shape
  // eyeTrackerDecayTime = 1;
  // eyeTrackerDecayTimeHold = 1;
  // mouseTrackerDecayTime = 1;
  // mouseTrackerDecayTimeHold = 1;

  // maxY = 1;
  // videoWidthHeight = 1;
  // originalWidthConst = 1;
  // originalHeightConst = 1;
  // pixelsRange = 0;
  videoUrl: string | ArrayBuffer = null;
  screenFile: File;
  subscriptions: Subscription;
  startTimestamp = new Date();
  videoApi: VgApiService;


  /// How close the event time should be to the video time to be considered to be displayed
  // trackerEventDisplayPrecision = 0.25;

  mouseEventRecords: CSVRecordMouseEvent[] = [];
  mouseMovementRecords: CSVRecordMouseMovement[] = [];
  eyeTrackingEventRecords: CSVRecordEyeTrackingEvent[] = [];

  // updateCounter = 0;

  // eyeTrackLastPosition: CSVRecordEyeTrackingEvent;
  // eyeTrackerLastDrawn: CanvasCircle;
  // eyeTrackPositionHold: CSVRecordEyeTrackingEvent;
  // eyeTrackPositionLastLongHold = new CSVRecordEyeTrackingEvent();

  // canvasDrawn: CanvasDrawn[] = [];
  requestInputs: FileDataType = FileDataType.MouseEventsSynced | FileDataType.MouseMovementsSynced | FileDataType.EyeTrackingSynced | FileDataType.ScreenVideo;

  get isVideoPlayerPlaying() {
    return this?.videoApi.state === VgStates.VG_PLAYING;
  }

  constructor(private snackbar: MatSnackBar, private cdr: ChangeDetectorRef, private comData: CommunicationService) { }

  // subscribe to the cached files so we can get the file we want

  ngOnInit() {
    const fileSub = this.comData.getCachedFile(FileDataType.ScreenVideo).subscribe((CachedFile) => {
      if (CachedFile) {
        this.screenFile = CachedFile.file;
        this.readVideoFile(CachedFile.file);
      }
    });

    const mouseEventSub = this.comData.getCachedFile(FileDataType.MouseEventsSynced).subscribe((CachedFile) => {
      if (CachedFile) {
        this.readMouseEvents(CachedFile.file);
      }
    });
    const mouseMovementSub = this.comData.getCachedFile(FileDataType.MouseMovementsSynced).subscribe((CachedFile) => {
      if (CachedFile) {
        this.readMouseMovements(CachedFile.file);
      }
    });
    const eyeTrackingSub = this.comData.getCachedFile(FileDataType.EyeTrackingSynced).subscribe((CachedFile) => {
      if (CachedFile) {
        this.readEyeTracking(CachedFile.file);
      }
    });

    this.subscriptions = new Subscription();
    this.subscriptions.add(fileSub);
    this.subscriptions.add(mouseEventSub);
    this.subscriptions.add(mouseMovementSub);
    this.subscriptions.add(eyeTrackingSub);
  }

  ngOnDestroy() {
    this.subscriptions?.unsubscribe();
    this.subscriptions = null;
  }

  readMouseEvents(file: File) {
    const u = new Utils();

    if (u.isValidCSVFile(file)) {
      //const filename = file.name;

      //const input = $event.target;
      const reader = new FileReader();
      reader.readAsText(file);
      let csvRecordsArray = [];
      this.mouseEventRecords = [];

      reader.onload = () => {
        const csvData = reader.result;
        csvRecordsArray = (csvData as string).split(/\r\n|\n/);
        this.startTimestamp = parse('0:00:00.0', 'HH:mm:ss.SSS', new Date());

        for (let i = 1; i < csvRecordsArray.length; i++) {
          const currentRecord = (csvRecordsArray[i] as string).split(',');
          const csvRecord: CSVRecordMouseEvent = new CSVRecordMouseEvent();

          const timeDate = parse(currentRecord[0].trim(), 'HH:mm:ss.SSS', new Date());
          csvRecord.timeColumn = this.getSecondsDifference(this.startTimestamp, timeDate);
          csvRecord.buttonNameColumn = currentRecord[1].trim();
          csvRecord.stateColumn = currentRecord[2].trim();
          this.mouseEventRecords.push(csvRecord);
        }
      };

      reader.onerror = () => {
        console.log('error is occurred while reading file!');
      };
    } else {
      this.snackbar.open('Uploaded file is invalid.', 'Dismiss');
    }
  }

  readMouseMovements(file: File) {
    const u = new Utils();

    if (u.isValidCSVFile(file)) {
      //const filename = file.name;

      //const input = $event.target;
      const reader = new FileReader();
      reader.readAsText(file);
      let csvRecordsArray = [];
      this.mouseMovementRecords = [];

      reader.onload = () => {
        const csvData = reader.result;
        csvRecordsArray = (csvData as string).split(/\r\n|\n/);

        this.startTimestamp = parse('0:00:00.0', 'HH:mm:ss.SSS', new Date());

        for (let i = 1; i < csvRecordsArray.length; i++) {
          const currentRecord = (csvRecordsArray[i] as string).split(',');
          const csvRecord: CSVRecordMouseMovement = new CSVRecordMouseMovement();

          const timeDate = parse(currentRecord[0].trim(), 'HH:mm:ss.SSS', new Date());
          csvRecord.timeColumn = this.getSecondsDifference(this.startTimestamp, timeDate);
          //console.log(' time in record ', csvRecord.timeColumn);
          csvRecord.xColumn = currentRecord[1].trim();
          csvRecord.yColumn = currentRecord[2].trim();
          this.mouseMovementRecords.push(csvRecord);
        }
      };

      reader.onerror = () => {
        console.log('error is occurred while reading file!');
      };
    } else {
      this.snackbar.open('Uploaded file is invalid.', 'Dismiss');
    }
  }

  readEyeTracking(file: File) {
    const u = new Utils();
    // const text = [];

    if (u.isValidCSVFile(file)) {
      //const filename = file.name;
      try {
        //const input = $event.target;
        const reader = new FileReader();
        reader.readAsText(file);
        let csvRecordsArray = [];
        this.eyeTrackingEventRecords = [];

        reader.onload = () => {
          const csvData = reader.result;
          csvRecordsArray = (csvData as string).split(/\r\n|\n/);
          this.startTimestamp = parse('0:00:00.0', 'HH:mm:ss.SSS', new Date());

          for (let i = 1; i < csvRecordsArray.length; i++) {
            const currentRecord = (csvRecordsArray[i] as string).split(',');
            const csvRecord: CSVRecordEyeTrackingEvent = new CSVRecordEyeTrackingEvent();

            const timeDate = parse(currentRecord[0].trim(), 'HH:mm:ss.SSS', new Date());
            csvRecord.timeColumn = this.getSecondsDifference(this.startTimestamp, timeDate);
            csvRecord.LeftEyeXColumn = Number(currentRecord[1].trim());
            csvRecord.LeftEyeYColumn = Number(currentRecord[2].trim());
            csvRecord.RightEyeXColumn = Number(currentRecord[3].trim());
            csvRecord.RightEyeYColumn = Number(currentRecord[4].trim());

            this.eyeTrackingEventRecords.push(csvRecord);
          }
          console.log('eye tracking count ', this.eyeTrackingEventRecords);
        };


        reader.onerror = () => {
          console.log('error occurred while reading file!');
        };
      } catch (e) {
        this.snackbar.open(`Failed to read the file`, 'Dismiss');
        throw e;
      }
    } else {
      this.snackbar.open('Uploaded file is invalid.', 'Dismiss');
    }

  }

  readVideoFile(file: File) {
    const reader = new FileReader();

    reader.onload = () => {
      this.videoUrl = reader.result;
    };
    reader.readAsDataURL(file);
  }

  fileChange(files: File[]) {
    if (files.length > 0) {
      this.screenFile = files[0];
    }
  }


  /**
   * this is used to perform the actual upload
   */
  upload() {
    console.log('sending this to server', this.screenFile);
  }

  onVideoReady(api: VgApiService) {
    if (!api) throw new Error("Video ready event did not provide a api object");
    this.videoApi = api;
    this.cdr.detectChanges();
  }

  playPauseVideo() {
    const video = this.videoApi;
    if (!this.isVideoPlayerPlaying) {
      video.play();
    } else {
      video.pause();
    }
  }

  onVideoEnd() {
    this.mouseEventRecords.forEach((event) => {
      event.eventShown = false;
    });

    this.eyeTrackingEventRecords.forEach((event) => {
      event.eventShown = false;
    });
  }

  getMatchingMousePosition(eventTime: number): CSVRecordMouseMovement {
    if (this.mouseMovementRecords.length === 0) {
      return null;
    }

    let closestPosition: CSVRecordMouseMovement | null = null;
    let closestDifference = Infinity;

    for (const position of this.mouseMovementRecords) {
      const timeDifference = Math.abs(eventTime - (position.timeColumn as number));

      if (timeDifference < closestDifference) {
        closestDifference = timeDifference;
        closestPosition = position;
      }
    }

    return closestPosition;
  }

  getSecondsDifference(startDate: Date, endDate: Date): number {
    const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
    const differenceInSeconds = differenceInMilliseconds / 1000;

    return differenceInSeconds;
  }
}

