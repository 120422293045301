<div id="converter-container">
  <section id="morae-data-upload-section">
    <h3>Upload Morae Data CSV events_data.csv to get timestamp</h3>
    <div id=morae-file-input>
      <button type="button" mat-raised-button color="primary" (click)="csvReader.click()">Choose file</button>
      <p id="morae-file-name"></p>
      <input hidden type="file" #csvReader name="Upload CSV" id="txtFileUpload" (change)="uploadListener($event)" accept=".csv" />
    </div>
    <p id="timestampText"></p>
  </section>

  <section id="me-data-upload-section" *ngIf="timestampExists">
    <h3>Upload CSV to convert timestamps </h3>
    <div id=morae-file-input>
      <button type="button" mat-raised-button color="primary" (click)="csvReader.click()">Choose file</button>
      <p id="other-file-name"></p>
      <input hidden type="file" name="Upload CSV" #csvReader id="txtFileUpload" (change)="uploadListener($event)" accept=".csv" />
    </div>
  </section>
</div>
