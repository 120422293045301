import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import Utils from '../utils';
import { CSVRecordEmotions, CSVRecordEmotionsAnalyzed } from '../shared/types/csv-model';
import { TimeStampModel } from '../shared/types/timestamp-model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-process-emotions-file',
  templateUrl: './process-emotions-file.component.html',
  styleUrls: ['./process-emotions-file.component.scss']
})

export class ProcessEmotionsFileComponent implements OnInit {
  public records: any[] = [];
  public updatedRecords: any[] = [];
  public csvReader: any;
  public timestampArr: string[] = [];
  public timestampAsModelArr: TimeStampModel[] = [];
  public startingTimestamp: any;
  public startingTSAsModel: TimeStampModel;
  public timestampStringSTArr: string[] = [];
  public csvRecordsArray: any[] = [];

  public isMoraeFile = false;
  public isMuseFile = false;
  public isEmotionFile = false;
  public isInvalidFile = false;
  public timestampExists = false;
  timeStampsAsSeconds: number;
  timeStampsAsSecondsArr: any[];
  strongestEmotion: string;
  secondStrongestEmotion: string;
  thirdStrongestEmotion: string;
  analyzedData: any[];

  file: File;

  @Output() sendEmotionsDataEvent = new EventEmitter<any[]>();

  constructor(private snackbar: MatSnackBar) { }

  ngOnInit() {
  }

  uploadListener($event: any) {
    const u = new Utils();
    const files = $event.srcElement.files;

    if (u.isValidCSVFile(files[0])) {
      this.file = files[0];

      const input = $event.target;
      const reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        const csvData = reader.result;
        this.csvRecordsArray = (csvData as string).split(/\r\n|\n/);
        const headersRow = u.getHeaderArray(this.csvRecordsArray);

        if (headersRow[0].substring(0, 12) === 'Elapsed Time') {
          this.isMoraeFile = true;
        } else if (headersRow[1] === 'angry') {
          this.isEmotionFile = true;
        } else if (headersRow[1] === 'delta_TP9') {
          this.isMuseFile = true;
        } else {
          this.isInvalidFile = true;
        }

        if (this.isEmotionFile) {
          this.analyzedData = [];

          // read CSV
          this.records = this.getDataRecordsArrayFromCSVFile(this.csvRecordsArray, headersRow.length);


          // remove NaN rows
          this.updatedRecords = this.removeNaNRows(this.records);

          // parse timestamps
          this.parseTimestampsAsModels(this.timestampArr);

          // timestamps as seconds
          this.timeStampsAsSecondsArr = this.convertTimestampsToSeconds(this.timestampAsModelArr);

          this.analyzedData = this.createAnalyzedEmotionsCSV(this.updatedRecords);
          this.sendEmotionsDataEvent.emit(this.analyzedData);

          this.saveToCSV(this.analyzedData);
        } else {
          this.snackbar.open('Uploaded file is invalid.', 'Dismiss');
        }
      };

      reader.onerror = () => {
        console.log('error is occurred while reading file!');
      };

    } else {
      this.snackbar.open('Please import valid .csv file.', 'Dismiss');
      this.csvReader.nativeElement.value = '';
      this.records = [];
    }
  }

  removeNaNRows(records: any) {
    const cleanedDataArray = [];
    for (let i = 0; i < records.length; i++) {
      if (records[i].angryColumn !== 'NaN' && records[i].angryColumn !== '0') {
        cleanedDataArray.push(records[i]);
        this.timestampArr.push(records[i].timestampColumn);
      }
    }
    return cleanedDataArray;
  }

  analyzeEmotions(emotions: CSVRecordEmotions) {
    const emotionScoresMap = new Map();
    emotionScoresMap.set('angry', parseFloat(emotions.angryColumn));
    emotionScoresMap.set('sad', parseFloat(emotions.sadColumn));
    emotionScoresMap.set('disgusted', parseFloat(emotions.disgustedColumn));
    emotionScoresMap.set('surprised', parseFloat(emotions.surprisedColumn));
    emotionScoresMap.set('happy', parseFloat(emotions.happyColumn));
    emotionScoresMap.set('neutral', parseFloat(emotions.neutralColumn));

    this.strongestEmotion = this.findMaxScore(emotionScoresMap)[0];
    emotionScoresMap.delete(this.strongestEmotion);
    this.secondStrongestEmotion = this.findMaxScore(emotionScoresMap)[0];
    if (this.findMaxScore(emotionScoresMap)[1] >= 0.05) {
      emotionScoresMap.delete(this.secondStrongestEmotion);
      this.thirdStrongestEmotion = this.findMaxScore(emotionScoresMap)[0];
      if (this.findMaxScore(emotionScoresMap)[1] >= 0.05) {
        emotionScoresMap.delete(this.thirdStrongestEmotion);
      } else {
        this.thirdStrongestEmotion = '';
      }
    } else {
      this.secondStrongestEmotion = '';
      this.thirdStrongestEmotion = '';
    }
  }

  findMaxScore(emotionScoresMap: any) {
    return [...emotionScoresMap.entries()].reduce((a, e) => e[1] > a[1] ? e : a);
  }

  createAnalyzedEmotionsCSV(cleanedDataArray: any) {
    const analyzedDataArray = [];
    for (let i = 0; i < cleanedDataArray.length; i++) {
      let emotion: CSVRecordEmotions = new CSVRecordEmotions();
      emotion = cleanedDataArray[i];
      const csvRecordAnalyzed: CSVRecordEmotionsAnalyzed = new CSVRecordEmotionsAnalyzed();
      csvRecordAnalyzed.timestampColumn = cleanedDataArray[i].timestampColumn;
      csvRecordAnalyzed.timeStampsAsSecondsColumn = this.timeStampsAsSecondsArr[i];
      csvRecordAnalyzed.angryColumn = cleanedDataArray[i].angryColumn;
      csvRecordAnalyzed.sadColumn = cleanedDataArray[i].sadColumn;
      csvRecordAnalyzed.disgustedColumn = cleanedDataArray[i].disgustedColumn;
      csvRecordAnalyzed.surprisedColumn = cleanedDataArray[i].surprisedColumn;
      csvRecordAnalyzed.happyColumn = cleanedDataArray[i].happyColumn;
      csvRecordAnalyzed.neutralColumn = cleanedDataArray[i].neutralColumn;
      this.analyzeEmotions(emotion);
      csvRecordAnalyzed.strongestEmotionColumn = this.strongestEmotion;
      csvRecordAnalyzed.secondStrongestEmotionColumn = this.secondStrongestEmotion;
      csvRecordAnalyzed.thirdStrongestEmotionColumn = this.thirdStrongestEmotion;
      analyzedDataArray.push([csvRecordAnalyzed.timestampColumn, csvRecordAnalyzed.timeStampsAsSecondsColumn, csvRecordAnalyzed.angryColumn, csvRecordAnalyzed.sadColumn, csvRecordAnalyzed.disgustedColumn, csvRecordAnalyzed.surprisedColumn,
      csvRecordAnalyzed.happyColumn, csvRecordAnalyzed.neutralColumn, csvRecordAnalyzed.strongestEmotionColumn, csvRecordAnalyzed.secondStrongestEmotionColumn, csvRecordAnalyzed.thirdStrongestEmotionColumn]);
    }
    return analyzedDataArray;
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    // Emotion
    const csvArr = [];

    for (let i = 1; i < csvRecordsArray.length; i++) {
      const currentRecord = (csvRecordsArray[i] as string).split(',');
      if (currentRecord.length === headerLength) {
        const csvRecord: CSVRecordEmotions = new CSVRecordEmotions();
        csvRecord.timestampColumn = currentRecord[0].trim();
        csvRecord.angryColumn = currentRecord[1].trim();
        csvRecord.sadColumn = currentRecord[2].trim();
        csvRecord.disgustedColumn = currentRecord[3].trim();
        csvRecord.surprisedColumn = currentRecord[4].trim();
        csvRecord.happyColumn = currentRecord[5].trim();
        csvRecord.neutralColumn = currentRecord[6].trim();
        csvArr.push(csvRecord);
      }
    }
    return csvArr;
  }

  parseTimestampsAsModels(timestampArr: any) {
    for (let i = 0; i < timestampArr.length; i++) {
      const timestampAsModel = new TimeStampModel();
      const timestampNS = timestampArr[i].split(':');
      timestampAsModel.hours = timestampNS[0];
      timestampAsModel.minutes = timestampNS[1];
      // Round up milliseconds up to standardize with Morae timetamps
      // const roundedTimestampSeconds = timestampNS[3].toFixed(2);
      // const timestampSecondsSplitNS = roundedTimestampSeconds[2].split('.');
      const timestampSecondsSplitNS = timestampNS[2].split('.');
      timestampAsModel.seconds = timestampSecondsSplitNS[0];
      timestampAsModel.millis = parseFloat(timestampSecondsSplitNS[1]);
      this.timestampAsModelArr.push(timestampAsModel);
    }
  }

  convertTimestampsToSeconds(timestampAsModelArr: TimeStampModel[]) {
    const timestampsAsSecondsArr = [];

    // convert timestamps in array into seconds
    timestampAsModelArr.forEach(element => {
      const timeStampSeconds = parseInt(element.seconds.toString()) + parseInt(element.minutes.toString()) * 60 + parseInt(element.hours.toString()) * 3600;
      timestampsAsSecondsArr.push(timeStampSeconds);
    });
    return timestampsAsSecondsArr;
  }

  saveToCSV(updatedRecords: any) {
    const a = document.createElement('a');
    let headers = [];
    if (this.isEmotionFile) {
      headers = ['time', 'time in seconds', 'angry', 'sad', 'disgusted', 'surprised', 'happy', 'neutral', 'strongest', '2nd strongest', '3rd strongest'];
      a.download = 'emotions_analyzed.csv';
      this.isEmotionFile = false;
    }
    /*else if (this.isMuseFile) {
      headers = ['time', 'delta_TP9', 'delta_AF7', 'delta_AF8', 'delta_TP10', 'theta_TP9', 'theta_AF7', 'theta_AF8', 'theta_TP10', 'alpha_TP9', 'alpha_AF7', 'alpha_AF8', 'alpha_TP10', 'beta_TP9', 'beta_AF7', 'beta_AF8', 'beta_TP10', 'gamma_TP9', 'gamma_AF7', 'gamma_AF8', 'gamma_TP10'];
      a.download = 'muse_synced.csv';
      this.isMuseFile = false;
    }*/
    // const headers = ['time', 'angry', 'sad', 'disgusted', 'surprised', 'happy', 'neutral'];
    const csvData = headers + '\n' + updatedRecords.map(item => item.join(',')).join('\n');
    const file = new Blob([csvData], { type: 'text/csv' });
    a.href = URL.createObjectURL(file);
    document.body.appendChild(a);
    // a.download = 'emotions_standardized.csv';
    a.click();
    document.body.removeChild(a);
    this.updatedRecords = [];
    this.timestampArr = [];
    this.timestampAsModelArr = [];
    this.timestampStringSTArr = [];
  }
}

