<div id="present-data-container">
  <div>
    <app-recorded-data-input [requestFilesFlag]="requestInputs" />
  </div>

  <section id="video-upload-component" style="margin-bottom: 5rem;">
    <app-steppable-video #videoPlayer [videoUrl]="videoUrl" (playerReady)="onVideoReady($event)" (ended)="onVideoEnd()">

      <app-tracker-data-display class="overlay" (mouseEvents)="mouseEventRecords"
        [eyeEvents]="eyeTrackingEventRecords" />
    </app-steppable-video>
  </section>
</div>
