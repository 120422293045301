<div id="clean-data-container">
    <section id="process-project-data-component">
      <app-process-project-data-file (timesEvent)="timesEvent($event)"></app-process-project-data-file>
    </section>
    <section id="emotions-data-upload-section">
      <app-process-emotions-file (sendEmotionsDataEvent)="sendEmotionsDataEvent($event)"></app-process-emotions-file>
    </section>
    <section id="process-muse-component">
      <app-process-muse-file (sendMuseDataEvent)="sendMuseDataEvent($event)"></app-process-muse-file>
    </section>
  </div>
  