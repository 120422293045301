import { Component, OnInit, AfterViewInit, ElementRef, Input, OnDestroy } from '@angular/core';
import { channelNames, EEGSample } from 'muse-js';
import { TimeSeries, SmoothieChart } from 'smoothie';
import { ChartService } from '../services/chart.service';
import { Observable, Subscription } from 'rxjs';
import { BandpassFilter } from '../shared/bandbass-filter';

import { addInfo } from '@neurosity/pipes';




@Component({
  selector: 'app-time-series',
  templateUrl: './time-series.component.html',
  styleUrls: ['./time-series.component.scss']
})

// const samplingFrequency = 256;

export class TimeSeriesComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() data: Observable<EEGSample>;

  // const eeg$ = fromEvent(bci, "data");

  filter = true;
  maxValue: number;
  minValue: number;

  readonly options;
  readonly colors;

  readonly channels = 5;
  readonly channelNames = channelNames.slice(0, this.channels);
  readonly charts = Array(this.channels).fill(0).map(() => new SmoothieChart(this.options));
  private readonly lines = Array(this.channels).fill(0).map(() => new TimeSeries());

  buffer;

  newData;

  subscription: Subscription;

  private readonly bandbassFilters: BandpassFilter[] = [];


  constructor(private view: ElementRef, private chartService: ChartService) {
    this.chartService = chartService;
    this.options = this.chartService.getChartSmoothieDefaults({
      millisPerPixel: 8,
      maxValue: this.maxValue,
      minValue: this.minValue,
    });
    this.colors = this.chartService.getColors();
    for (let i = 0; i < this.channels; i++) {
      this.bandbassFilters[i] = new BandpassFilter(256, 1, 30);
    }
  }

  ngOnInit() {
    this.maxValue = 200;
    this.minValue = -200;
    const dataSub = this.data.subscribe(
      (data) => { this.newData = data; }
    );


    // this.data.pipe(

    //     //epoch({ duration: 256, interval: 100 }),
    //     //fft({ bins: 256 }),
    //     //alphaPower(),
    //     //map((data) => alphaPower()),
    //     //averagePower(),
    // ).subscribe((buffer) => {
    //         console.log(buffer);
    //         //this.newData['buffer'] = buffer;
    //         //console.log(this.newData);
    //     }
    // )
    this.addTimeSeries();
    const dataSub2 = this.data
      .pipe(
        addInfo({ channelNames: this.channelNames })
      )
      .subscribe((sample) => {
        // console.log(sample);
        sample.data.slice(0, this.channels).map((value, electrode) => {
          this.draw(sample.timestamp, value, electrode);
        });
      });

    this.subscription = new Subscription();
    this.subscription.add(dataSub);
    this.subscription.add(dataSub2);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.subscription = null;
  }

  addTimeSeries() {
    this.lines.forEach((line: TimeSeries, index) => {
      this.charts[index].addTimeSeries(line, {
        lineWidth: 2,
        strokeStyle: this.colors[index].borderColor
      });
    });
  }

  ngAfterViewInit() {
    const channels = this.view.nativeElement.querySelectorAll('canvas');

    this.charts.forEach((canvas, index) => {
      canvas.streamTo(channels[index]);
    });
  }

  draw(timestamp: number, amplitude: number, index: number) {
    const filter = this.bandbassFilters[index];
    if (this.filter && !isNaN(amplitude)) {
      amplitude = filter.next(amplitude);
      if (amplitude > this.maxValue) {
        this.maxValue = amplitude;
      } else {
        this.maxValue = 200;
      }
      if (amplitude < this.minValue) {
        this.minValue = amplitude;
      } else {
        this.minValue = -200;
      }
    }
    this.lines[index].append(timestamp, amplitude);
  }
}

