import { Component, OnDestroy, OnInit } from '@angular/core';
import * as faceapi from '@vladmandic/face-api';
import { environment } from '../../environments/environment';
import { CommunicationService } from '../services/communication.service';
import { formatTimeUTC } from '../utils';
import { ScreenRecorderComponent } from '../data-recorder/data-recorder.component';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

// consider using https://www.npmjs.com/package/@vladmandic/human, is more up to date
faceapi.env.monkeyPatch({
  Canvas: HTMLCanvasElement,
  Image: HTMLImageElement,
  ImageData,
  Video: HTMLVideoElement,
  createCanvasElement: () => document.createElement('canvas'),
  createImageElement: () => document.createElement('img'),
});

@Component({
  selector: 'app-webcam-emotion-recognition',
  templateUrl: './webcam-emotion-recognition.component.html',
  styleUrls: ['./webcam-emotion-recognition.component.scss'],
})
export class WebcamEmotionRecognitionComponent implements OnInit, OnDestroy {

  private recognitionInterval = 1000;

  private sad;
  private angry;
  private disgusted;
  private surprised;
  private happy;
  private neutral;
  private video: HTMLVideoElement;
  private canvas;
  private displaySize;
  private emotionData = [];
  private timeStampString;
  private startEpoch = 0;
  private endEpoch = 0;

  recordingMessage: boolean;
  camReadyMessage: boolean;
  wasRunning = false;
  cameraReady: boolean;
  timer: any;

  private subscription: Subscription;

  constructor(private comData: CommunicationService, private snackbar: MatSnackBar) {
  }

  ngOnInit() {
    this.cameraReady = false;
    this.loadModels();
    const recordingSub = this.comData.isRecording.subscribe((isRecording) => {
      this.recordingMessage = isRecording;
      if (this.recordingMessage === true) {
        this.startRecording();
      } else if (this.wasRunning && this.recordingMessage === false) {
        this.stopRecording();
      }
    });
    const emotionSub = this.comData.emotionRecognitionReady.subscribe(
      (camMessage) => (this.camReadyMessage = camMessage)
    );

    document.addEventListener('keydown', (event) => {
      if (event.code === ScreenRecorderComponent.recordHotkey) {
        const date = new Date();
        this.endEpoch = Date.now();
        this.timeStampString = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.${date.getMilliseconds()}`;
        this.emotionData.push([this.timeStampString, 0, 0, 0, 0, 0, 0]);
      }
    });

    this.subscription = new Subscription();
    this.subscription.add(recordingSub);
    this.subscription.add(emotionSub);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.subscription = null;
  }

  startCamStream() {
    this.video = document.getElementById('video') as HTMLVideoElement;
    if (!this.video) throw new Error('Video element not found');
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => this.addCanPlayListener(stream))
        .catch((error) => {
          this.snackbar.open('Camera is not ready yet or camera permissions are missing', 'Dismiss');
          throw new Error('Error with camera stream: ' + error);
        });
    }
  }

  async addCanPlayListener(stream: MediaStream) {
    this.video.srcObject = stream;
    this.video.addEventListener('canplay', async () => this.onCanPlayVideo(), { passive: true });
  }

  async onCanPlayVideo() {
    this.displaySize = {
      width: this.video.width,
      height: this.video.height,
    };
    const canvas = faceapi.createCanvasFromMedia(this.video);
    this.video.parentNode.append(canvas);
    canvas.style.position = 'absolute';
    canvas.style.left = '0';
    canvas.style.top = '0';
    this.canvas = canvas;
    faceapi.matchDimensions(canvas, this.displaySize);
    // first face detection call takes 1400 ms, subsequent calls take about 50 ms
    this.timer = setInterval(async () => this.runEmotionRecognition(), this.recognitionInterval);
  }

  async runEmotionRecognition() {
    // const detections = await faceapi.detectSingleFace(this.video).withFaceLandmarks()
    // .withFaceExpressions().withFaceDescriptor();
    // const sfDetection = await faceapi.detectSingleFace(this.video, new faceapi.TinyFaceDetectorOptions());
    // console.log(sfDetection);
    console.time('detectSingleFace');
    const detections = await this.getFaceEmotions();
    console.timeEnd('detectSingleFace');
    // console.log(detections);
    const date = new Date();
    this.timeStampString = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.${date.getMilliseconds()}`;
    if (this.wasRunning) {
      if (detections === undefined) {
        this.emotionData.push([
          this.timeStampString,
          'NaN',
          'NaN',
          'NaN',
          'NaN',
          'NaN',
          'NaN',
        ]);
      } else {
        this.angry = detections.expressions.angry.valueOf();
        this.sad = detections.expressions.sad.valueOf();
        this.disgusted = detections.expressions.disgusted.valueOf();
        this.surprised = detections.expressions.surprised.valueOf();
        this.happy = detections.expressions.happy.valueOf();
        this.neutral = detections.expressions.neutral.valueOf();
        this.emotionData.push([
          this.timeStampString,
          this.angry,
          this.sad,
          this.disgusted,
          this.surprised,
          this.happy,
          this.neutral,
        ]);
      }
    }
    if (detections !== undefined) {
      const resizedDetections = faceapi.resizeResults(
        detections,
        this.displaySize
      );
      this.canvas
        .getContext('2d')
        .clearRect(0, 0, this.canvas.width, this.canvas.height);
      const minProbability = 0.05;
      /* if (this.wasRunning) {
      this.emotionData.push([this.timeStampString, this.angry, this.sad, this.disgusted, this.surprised,
      this.happy, this.neutral]);
    } */
      faceapi.draw.drawFaceExpressions(
        this.canvas,
        resizedDetections,
        minProbability
      );
      faceapi.draw.drawDetections(this.canvas, resizedDetections);
      if (!this.cameraReady) {
        this.setStatus(true);
        this.cameraReady = true;
      }
    }
  }

  async getFaceEmotions() {
    return faceapi
      .detectSingleFace(
        this.video,
        new faceapi.TinyFaceDetectorOptions()
      )
      .withFaceExpressions();
  }

  setStatus(camReadyBoolean) {
    this.comData.changeEmotionRecognitionReady(camReadyBoolean);
  }

  saveToCSV(emotionData: any) {
    const a = document.createElement('a');
    const headers = [
      'time',
      'angry',
      'sad',
      'disgusted',
      'surprised',
      'happy',
      'neutral',
    ];
    const csvData =
      headers + '\n' + emotionData.map((item) => item.join(',')).join('\n');
    const file = new Blob([csvData], { type: 'text/csv' });
    a.href = URL.createObjectURL(file);
    document.body.appendChild(a);
    a.download = 'emotions_raw.csv';
    a.click();
    document.body.removeChild(a);
  }

  loadModels() {
    const modelUrl = environment.modelUrl;
    Promise.all([
      faceapi.nets.ssdMobilenetv1.loadFromUri(modelUrl),
      faceapi.nets.tinyFaceDetector.loadFromUri(modelUrl),
      faceapi.nets.faceLandmark68Net.loadFromUri(modelUrl),
      faceapi.nets.faceExpressionNet.loadFromUri(modelUrl),
      faceapi.nets.faceRecognitionNet.loadFromUri(modelUrl),
    ]).then(() => this.startCamStream());
  }

  startRecording() {
    this.wasRunning = true;
    this.startEpoch = Date.now();
  }

  stopRecording() {
    this.cameraReady = false;
    this.setStatus(false);
    this.wasRunning = false;
    const formattedEndTime = formatTimeUTC(this.endEpoch - this.startEpoch);

    this.saveToCSV(this.emotionData);
    this.emotionData = [];
    // this.canvas.getContext('2d').clearRect(16, 16, this.canvas.width, this.canvas.height);
    this.saveMorayCsv(formattedEndTime);
  }

  saveMorayCsv(endTime: string) {
    const headers = [
      'Elapsed Time',
      'Recording',
      'Task',
      'Event',
      'Details',
      'Application',
      'Owner',
      'Notes',
      'URL',
      'Page Title',
      'Title',
      'Score',
      'X [px]',
      'Y [px]',
      'Duration [ms]',
    ];
    const morayData = [];
    morayData.push(headers);

    const csvDataWithBom = '\ufeff' + morayData.map((item) => item.join('\t')).join('\r\n');
    const blob = new Blob([csvDataWithBom], { type: 'text/csv' });

    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = 'events_data.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
