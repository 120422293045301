import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

enum WebSocketAction {
  Ping,
  GetCalibration,
  Calibrate,
  StartLoggingMyGaze,
  StartLoggingHardware,
  EndLogging,
  SetSessionName,
}

// These are lower case because otherwise it would be difficult to make them match with the websocket server data
export enum WebSocketResponseAction {
  pong,
  systemReady,
  gotCalibrationStatus,
  loggingEnded,
  failedStartLogging,
  failedReceivingData,
  loggingStarted,
  failedSettingSessionName,
  successfullySetSessionName,
  handlerNotRunning,
}

export enum ConnectionStatus {
  NotConnected,
  Connecting,
  Connected,
  UnableToConnect
}

export enum CalibrationStatus {
  unknownCalibrationStatus,
  noCalibration,
  validCalibration,
  performingCalibration
}

export interface WebSocketResponse {
  Action: keyof WebSocketResponseAction;
  Data: string;
}

export type MessageHandlerCallback = (response: WebSocketResponse) => void;
export type ConnectionStatusCallback = (status: ConnectionStatus) => void;

@Injectable()
export default class GazeHandler {
  private readonly websocket: WebSocket;
  private socketOpen = false;
  systemReady = false;
  private pingInterval;
  private readonly handleMessageCb: MessageHandlerCallback;
  private readonly connectionStatusCallback: ConnectionStatusCallback;
  private readonly eyeTrackerEnabled: boolean;
  private readonly mouseAndKeyboardEnabled: boolean;

  constructor(
    handleMessageCb: MessageHandlerCallback,
    connectionStatusCallback: ConnectionStatusCallback,
    eyeTrackerEnabled: boolean,
    mouseAndKeyboardEnabled: boolean
  ) {
    this.handleMessageCb = handleMessageCb;
    this.connectionStatusCallback = connectionStatusCallback;
    this.eyeTrackerEnabled = eyeTrackerEnabled;
    this.mouseAndKeyboardEnabled = mouseAndKeyboardEnabled;

    this.websocket = new WebSocket(
      environment.myGazeApiUrl
    );

    this.websocket.onopen = this.HandleOpen.bind(this);
    this.websocket.onerror = this.HandleError.bind(this);
    this.websocket.onclose = this.HandleClose.bind(this);
    this.websocket.onmessage = this.HandleMessage.bind(this);
  }

  private HandleOpen() {
    this.socketOpen = true;
    this.ResetPing();
  }

  private HandleError(event: Event) {
    console.error(event);
    if (!this.socketOpen && !this.systemReady) {
      this.connectionStatusCallback(ConnectionStatus.UnableToConnect);
      throw new Error("Error connecting to websocket server");
    }
  }

  private HandleClose() {
    this.socketOpen = false;
    this.systemReady = false;
    this.connectionStatusCallback(ConnectionStatus.NotConnected);

    clearInterval(this.pingInterval);
  }

  private HandleMessage(event: MessageEvent) {

    const parsed: WebSocketResponse = JSON.parse(event.data);
    switch (parsed.Action) {
      case WebSocketResponseAction[WebSocketResponseAction.pong]: {
        this.ResetPing();
        break;
      }
      case WebSocketResponseAction[WebSocketResponseAction.systemReady]: {
        this.systemReady = true;
        if (typeof this.connectionStatusCallback === 'function') {
          this.connectionStatusCallback(ConnectionStatus.Connected);
        }
        break;
      }
      default: {
        this.handleMessageCb(parsed);
        break;
      }
    }
  }

  /**
   * Check and keep connection alive
   */
  private ResetPing() {
    clearInterval(this.pingInterval);
    this.pingInterval = setInterval(this.Ping.bind(this), 15 * 1000);
  }

  private Ping() {
    this.Send(WebSocketAction.Ping);
  }

  private async Send(Action: WebSocketAction, Data: string = '') {
    if (this.websocket.readyState !== WebSocket.OPEN) {
      return false;
    }

    this.websocket.send(
      JSON.stringify(
        { Action, Data }
      )
    );
    return true;
  }

  GetCalibration() {
    this.Send(WebSocketAction.GetCalibration);
  }

  Calibrate() {
    this.Send(WebSocketAction.Calibrate);
  }

  SetSessionName(sessionName: string) {
    this.Send(WebSocketAction.SetSessionName, sessionName);
  }

  StartLogging() {
    if (this.eyeTrackerEnabled) {
      this.Send(WebSocketAction.StartLoggingMyGaze);
    }
    if (this.mouseAndKeyboardEnabled) {
      this.Send(WebSocketAction.StartLoggingHardware);
    }
  }

  EndLogging() {
    this.Send(WebSocketAction.EndLogging);
  }

  Disconnect() {
    this.websocket.close();
    this.HandleClose();
  }
}
