import { AfterViewInit, Component, Input } from '@angular/core';
import { CSVRecordEyeTrackingEvent, CSVRecordMouseEvent } from '../shared/types/csv-model';
import { VgStates } from '@videogular/ngx-videogular/core';
import CanvasDrawn, { CanvasCircle, CanvasLine, CanvasSquare, CanvasTriangle } from '../redrawing-canvas/canvas-drawn-model-class';
import { DrawnShape } from '../redrawing-canvas/present-data-enums';
import { VideoService } from '../services/video.service';
import { VGApiServiceEx } from '../shared/types/VGApiService';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tracker-data-display',
  template: '<app-redrawing-canvas [canvasDrawn]="canvasDrawn" />',
})

export class TrackerDataDisplayComponent implements AfterViewInit {

  @Input() mouseEvents: CSVRecordMouseEvent[];
  @Input() api: VGApiServiceEx;
  @Input() eyeEvents: CSVRecordEyeTrackingEvent[];

  private subscriptions: Subscription;

  eyeTrackTimer?: NodeJS.Timeout;
  mouseEventTimer?: NodeJS.Timeout;

  lastEyeTrackEvent?: CSVRecordEyeTrackingEvent;
  lastMouseEvent?: CSVRecordMouseEvent;

  /// what we're gonna output to the canvas, the shapes we want drawn
  canvasDrawn: CanvasDrawn[] = [];

  public get currentTime(): number {
    if (!this.api) return 0;
    return this.api.currentTime;
  }

  public get isPaused(): boolean {
    if (!this.api) return true;
    if (this.api.state === VgStates.VG_PAUSED) return true;
    return false;
  }

  constructor(private videoService: VideoService) {
  }

  ngAfterViewInit() {
    this.videoService.api.subscribe((api) => this.hookToApi(api));
  }

  hookToApi(api: VGApiServiceEx) {
    if (!api) return;
    this.api = api;
    console.log('hooking to api', api);

    this.subscriptions = new Subscription();
    this.subscriptions.add(this.api.subscriptions.timeUpdate.subscribe(() => this.onVideoTimeUpdate()));
    this.subscriptions.add(this.api.subscriptions.play.subscribe(() => this.onPlayVideo()));
    this.subscriptions.add(this.api.subscriptions.pause.subscribe(() => this.onPauseVideo()));
    this.subscriptions.add(this.api.subscriptions.ended.subscribe(() => this.stopVideoTimer()));
    this.subscriptions.add(this.api.subscriptions.abort.subscribe(() => this.onAbortVideo()));
  }

  onPlayVideo() {
    this.nextEyeTrackEvent();
  }

  onPauseVideo() {
    if (this.mouseEventTimer) {
      clearInterval(this.mouseEventTimer);
    }
    this.mouseEventTimer = null;

    if (this.eyeTrackTimer) {
      clearInterval(this.eyeTrackTimer);
    }
    this.eyeTrackTimer = null;
  }

  onAbortVideo() {
    this.cleanupSubscriptions();
  }

  stopVideoTimer() {
    console.log('video stopped');
  };

  cleanupSubscriptions() {
    this.subscriptions?.unsubscribe();
    this.subscriptions = null;
  }

  // updateTimer() {
  //   // why is the timer a canvas?
  //   const timeCanvas = this.timeCanvasElement.nativeElement;
  //   const timeContext = timeCanvas.getContext('2d');
  //   const video = this.videoPlayer.instance.videoPlayer;
  //   timeContext.clearRect(0, 0, timeCanvas.width, timeCanvas.height);
  //   timeContext.fillText(`Time: ${formatTimeWithWords(video.currentTime * 1000)}`, 10, 30);
  // }

  async onVideoTimeUpdate() {
    console.log('video time update', this.currentTime);

    // if you add in eye tracking data, this lets us load it without needing to restart the video
    if (!this.eyeTrackTimer) {
      this.nextEyeTrackEvent();
    }
    this.timeOutDraws(this.currentTime);
    // this.updateCounter++;
    // const video = this.videoPlayer.instance.videoPlayer;
    // const canvas = this.canvasElement.nativeElement;
    // const context = canvas.getContext('2d');

    // // currentTime is
    // const currentTime = video.currentTime;

    // const matchingTimestamp = this.mouseEventRecords.find(
    //   (mouseEventRecord) => Math.abs(currentTime - mouseEventRecord.timeColumn) < this.trackerEventDisplayPrecision && mouseEventRecord.stateColumn === 'MouseDown'
    //     && mouseEventRecord.eventShown === false
    // );

    // if (matchingTimestamp) {
    //   console.log('matching timestamp ', matchingTimestamp);

    //   matchingTimestamp.eventShown = true;
    //   const isRightClick = matchingTimestamp.buttonNameColumn === 'RightMouseButton';
    //   const matchingMousePosition = this.getMatchingMousePosition(matchingTimestamp.timeColumn);

    //   if (matchingMousePosition != null) {
    //     const x = Math.round(matchingMousePosition.xColumn * this.originalWidthConst);
    //     const y = Math.round((matchingMousePosition.yColumn - this.sharedPanelHeight) * this.originalHeightConst);


    //     if (isRightClick) {
    //       this.drawSquare(context, x, y, 'yellow', this.mouseTrackerDecayTime + currentTime);
    //     } else {
    //       this.drawTriangle(context, x, y, 'green', this.mouseTrackerDecayTime + currentTime);
    //     }
    //   }
    // }
    // this.timeOutDraws(context, currentTime);
  }

  async nextEyeTrackEvent() {
    if (!this.eyeEvents) return;
    // find the NEXT eye track event after currentTime
    const nextEyeTrackEvent = this.eyeEvents.find(eyeTrackEventRecord => !eyeTrackEventRecord.eventShown && eyeTrackEventRecord.timeColumn >= this.currentTime);
    if (!nextEyeTrackEvent || this.isPaused) return;
    // recursively calls itself until we reach the last eye track event
    const msToNextEvent = (nextEyeTrackEvent.timeColumn - this.currentTime) * 1000;
    if (nextEyeTrackEvent.timeColumn === this.currentTime) {
      this.handleEyeTrackerEvent(nextEyeTrackEvent);
      this.nextEyeTrackEvent();
    } else {
      this.eyeTrackTimer = setTimeout(() => {
        this.handleEyeTrackerEvent(nextEyeTrackEvent);
        this.nextEyeTrackEvent();
      }, msToNextEvent);
    }
  }

  async handleEyeTrackerEvent(eyeTrackTimestamp: CSVRecordEyeTrackingEvent) {
    let radiusSize = 10;
    const decayTime = 2;
    let color = 'red';

    console.log('checking timestamp:', eyeTrackTimestamp.timeColumn, 'current time:', this.currentTime);

    if (eyeTrackTimestamp) {
      //   // where to draw the eye tracker circle and line
      const x = this.getEyeTrackShapeWidth(eyeTrackTimestamp);
      const y = this.getEyeTrackShapeHeight(eyeTrackTimestamp);

      const foundConflictingDrawn = this.removeEyeDrawn(eyeTrackTimestamp, radiusSize);

      //   // timing to measure how long between eye track events
      if (foundConflictingDrawn) {
        //     decayTime = this.eyeTrackerDecayTimeHold;
        const lookTimeRadius = 5;
        console.log('eye has been same place for ', lookTimeRadius);

        //     // decay the circle we are on top of so we can draw a bigger one
        color = foundConflictingDrawn.color;
        radiusSize = foundConflictingDrawn.radius + lookTimeRadius;
        // this.eyeTrackPositionHold = eyeTrackTimestamp;
      } else if (this.lastEyeTrackEvent) {
        const lineX2 = this.getEyeTrackShapeWidth(this.lastEyeTrackEvent);
        const lineY2 = this.getEyeTrackShapeHeight(this.lastEyeTrackEvent);
        this.drawLine(x, y, lineX2, lineY2, color, decayTime);
        console.log('drawing line from ', x, y, ' to ', lineX2, lineY2);
      }
      eyeTrackTimestamp.eventShown = true;
      console.log('drawing circle at ', x, y, ' with radius ', radiusSize);
      this.drawCircle(x, y, radiusSize, color, decayTime + this.currentTime);
      // this.eyeTrackLastPosition = eyeTrackTimestamp;
    }
    this.timeOutDraws(this.currentTime);
  }

  isSamePosition(firstEyePosition: CSVRecordEyeTrackingEvent, currentEyePosition: CSVRecordEyeTrackingEvent, radius = 10) {
    if (firstEyePosition && currentEyePosition) {
      return currentEyePosition.LeftEyeYColumn - firstEyePosition.LeftEyeYColumn < radius
        && currentEyePosition.LeftEyeXColumn - firstEyePosition.LeftEyeXColumn < radius;
    }
    return false;
  }

  drawLine(x1: number, y1: number, x2: number, y2: number, color = 'red', deleteIn = 0) {
    const line = new CanvasLine(x1, y1, x2, y2, color, deleteIn);
    this.canvasDraw(line);
    return line;
  }

  drawTriangle(centerX: number, centerY: number, color = 'red', deleteIn = 0) {
    const sideLength = 15;
    const triangle = new CanvasTriangle(centerX, centerY, color, DrawnShape.TRIANGLE, sideLength, deleteIn);
    this.canvasDraw(triangle);
    return triangle;
  }

  drawSquare(centerX: number, centerY: number, color = 'red', deleteIn = 0) {
    const sideLength = 15;
    const square = new CanvasSquare(centerX, centerY, color, DrawnShape.SQUARE, sideLength, deleteIn);
    this.canvasDraw(square);
    return square;
  }

  drawCircle(centerX: number, centerY: number, radius: number, color = 'red', removeIn = 0) {
    const circle = new CanvasCircle(centerX, centerY, color, DrawnShape.CIRCLE, radius, removeIn);
    this.canvasDraw(circle);
    return circle;
  }

  canvasDraw(toDraw: CanvasDrawn) {
    // toDraw.draw(context);
    this.canvasDrawn.push(toDraw);
    return true;
  }

  removeEyeDrawn(eyeTrackTimestamp: CSVRecordEyeTrackingEvent, radius: number) {
    // const matchingEyeDrawn = this.canvasDrawn.find((element) =>
    //   element.x === eyeTrackTimestamp.LeftEyeXColumn && element.y === eyeTrackTimestamp.LeftEyeYColumn && element.deleteIn === eyeTrackTimestamp.timeColumn);
    // find any drawn shapes that collide with the eye track event, must check if any drawn shapes are within the radius of the eye track event

    const matchingEyeDrawn = this.canvasDrawn.find((element) =>
      element.x < eyeTrackTimestamp.LeftEyeXColumn + radius
      && element.x > eyeTrackTimestamp.LeftEyeXColumn - radius
      && element.y < eyeTrackTimestamp.LeftEyeYColumn + radius
      && element.y > eyeTrackTimestamp.LeftEyeYColumn - radius
    );
    // there shouldn't be more than one drawn shape at the same position, since each drawn shape removes any conflicting shapes
    if (matchingEyeDrawn) {
      this.canvasDrawn = this.canvasDrawn.filter((element) => element !== matchingEyeDrawn);
      return matchingEyeDrawn;
    }
    return null;
  }

  removeDrawn(drawn: CanvasDrawn) {
    this.canvasDrawn = this.canvasDrawn.filter((element) => element !== drawn);
  }

  getEyeTrackShapeWidth(eyeTrackTimestamp: CSVRecordEyeTrackingEvent) {
    const originalWidthConst = 1;
    return eyeTrackTimestamp.LeftEyeXColumn * originalWidthConst;
  }

  getEyeTrackShapeHeight(eyeTrackTimestamp: CSVRecordEyeTrackingEvent) {
    const originalHeightConst = 1;
    const sharedPanelHeight = 0;
    return (eyeTrackTimestamp.LeftEyeYColumn - sharedPanelHeight) * originalHeightConst;
  }

  async timeOutDraws(videoTime: number) {
    this.canvasDrawn = this.canvasDrawn.filter((element) => element.deleteIn !== -1 && element.deleteIn > videoTime);
  }
};
