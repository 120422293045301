import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { EEGSample, MuseClient, MuseControlResponse, zipSamples } from 'muse-js';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommunicationService } from '../services/communication.service';
import { tap, share } from 'rxjs/operators';
import { ScreenRecorderComponent } from '../data-recorder/data-recorder.component';
import { RecordingUserConfig } from '../shared/types/user-config';

@Component({
  selector: 'app-recording',
  templateUrl: './recording.component.html',
  styleUrls: ['./recording.component.scss']
})
export class RecordingComponent implements OnInit, OnDestroy {
  museConnecting = false;
  museConnected = false;
  museReady = false;
  batteryLevelDataAvailable = false;
  data: Observable<EEGSample> | null;
  batteryLevel: number | null;
  controlResponses: Observable<MuseControlResponse>;

  private muse = new MuseClient();

  consoleAppReady: boolean;
  readyToRecord: boolean;
  isRecording: boolean;
  faceRecognitionReady: boolean;
  userSettings: RecordingUserConfig;
  get userSettingsAsArray(): string[] {
    return Object.keys(this.userSettings);
  }

  private subscription: Subscription;

  chooseWindowHotkey = ScreenRecorderComponent.chooseWindowHotkey;
  recordHotkey = ScreenRecorderComponent.recordHotkey;

  constructor(private cd: ChangeDetectorRef, private snackbar: MatSnackBar, private comData: CommunicationService) { }


  ngOnInit() {
    const consoleSub = this.comData.consoleAppReady.subscribe(consoleAppReady => this.consoleAppReady = consoleAppReady);
    const museSub = this.comData.museReady.subscribe(museReady => this.museReady = museReady);
    const canStartSub = this.comData.canStartRecording.subscribe(readyToRecord => this.readyToRecord = readyToRecord);
    const isRecordingSub = this.comData.isRecording.subscribe(isRecording => this.isRecording = isRecording);
    const emotionRecSub = this.comData.emotionRecognitionReady.subscribe(faceRecognitionReady => this.faceRecognitionReady = faceRecognitionReady);
    const userSettingSub = this.comData.recordingSettings.subscribe(userSettings => this.userSettings = userSettings);

    this.muse.connectionStatus.subscribe(status => {
      this.museConnected = status;
      this.data = null;
      this.museReady = false;
      this.batteryLevel = null;
    });

    this.muse.enableAux = false;

    this.subscription = new Subscription();
    this.subscription.add(consoleSub);
    this.subscription.add(museSub);
    this.subscription.add(canStartSub);
    this.subscription.add(isRecordingSub);
    this.subscription.add(emotionRecSub);
    this.subscription.add(userSettingSub);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.subscription = null;
  }

  consoleAppRequired() {
    return this.comData.consoleAppRequired();
  }

  async connectMuse() {
    this.museConnecting = true;
    try {
      await this.muse.connect();
      // muse uses a internal version of Observable that isn't compatible
      this.controlResponses = this.muse.controlResponses;
      await this.muse.start();

      this.muse.telemetryData.subscribe((obj) => {
        this.batteryLevel = obj.batteryLevel;
        this.batteryLevelDataAvailable = true;
      });
      this.comData.changeMuseReady(true);
      this.stream();

    } catch (err) {
      this.snackbar.open('Connection failed:' + err.toString(), 'Dismiss');
      console.log(err);
    } finally {
      this.museConnecting = false;
    }
  }

  stream() {
    this.data = this.muse.eegReadings.pipe(
      zipSamples,
      tap(() => this.cd.detectChanges),
      share()
    );
    this.museReady = true;
  }

  disconnect() {
    this.muse.disconnect();
    this.comData.changeMuseReady(false);

    this.batteryLevelDataAvailable = false;
  }

  // split up by capital letters and add a space between them, and then capitalize the first letter
  formatStringToTitleCase(str: string) {
    return str.split(/(?=[A-Z])/).join(' ').replace(/^\w/, c => c.toUpperCase());
  }

  toggleSettings($value: string) {
    this.comData.changeUserSettings({ [$value]: !this.userSettings[$value] });
  }
}
