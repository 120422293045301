import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import CanvasDrawn from './canvas-drawn-model-class';

@Component({
  selector: 'app-redrawing-canvas',
  template: '<canvas #canvasElement [width]="width" [height]="height" style="position: absolute; height: 100%; width: 100%;" ></canvas>',
})
export class RedrawingCanvasComponent implements AfterViewInit {
  // TODO use requestAnimationFrame to redraw the canvas
  width = 0;
  height = 0;

  private _canvasDrawn: CanvasDrawn[] = [];
  @Input()
  get canvasDrawn(): CanvasDrawn[] {
    return this._canvasDrawn;
  }
  set canvasDrawn(value: CanvasDrawn[]) {
    this._canvasDrawn = value;
    this.scheduleRedraw();
  }

  @ViewChild('canvasElement') canvas: ElementRef<HTMLCanvasElement>;
  private context: CanvasRenderingContext2D;

  @Output() onShapeDecay = new EventEmitter<CanvasDrawn[]>();

  ngAfterViewInit() {
    this.context = this.canvas.nativeElement.getContext('2d');
  }

  private scheduleRedraw() {
    return requestAnimationFrame(() => this.reDrawCanvas());
  }

  private reDrawCanvas() {
    if (!this.canvasDrawn.length || !this.context) return;
    console.time('redraw');
    const newWidth = Number(this.canvas.nativeElement.offsetWidth);
    const newHeight = Number(this.canvas.nativeElement.offsetHeight);

    if (newWidth !== this.width && !isNaN(newWidth) && newHeight !== this.height && !isNaN(newHeight)) {
      this.width = newWidth;
      this.height = newHeight;
    }
    this.context.clearRect(0, 0, this.context.canvas.width, this.context.canvas.height);
    this.canvasDrawn.forEach((element) => element.draw(this.context));
    console.timeEnd('redraw');
  }
}
