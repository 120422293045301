import { BehaviorSubject, distinctUntilChanged, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { RecordingUserConfig } from '../shared/types/user-config';
import { CachedFile } from '../file-input/request-files';
import { FileDataType } from '../recorded-data-input/recorded-data-enums';

// TODO split this up into recording/file input services
@Injectable({ providedIn: 'root' })
export class CommunicationService {
  private _museReady = new BehaviorSubject(false);
  private _consoleAppReady = new BehaviorSubject(false);
  /// true if everything we need is ready to start recording
  private _canStartRecording = new BehaviorSubject(false);
  /// Whether or not the emotion recognition component is ready
  private _emotionRecognitionReady = new BehaviorSubject(false);
  /// if we are actively recording right now
  private _isRecording = new BehaviorSubject(false);
  /// settings that the user defines
  private _recordingSettings = new BehaviorSubject<RecordingUserConfig>({
    eyeTracking: true,
    mouseAndKeyboard: true,
    muse: true,
    screenRecording: true,
    faceRecording: true
  });
  // cached files to save between page changes
  private _cachedFiles = new BehaviorSubject<CachedFile[]>([]);

  readonly museReady = this._museReady.asObservable();
  readonly consoleAppReady = this._consoleAppReady.asObservable();
  readonly canStartRecording = this._canStartRecording.asObservable();
  readonly emotionRecognitionReady = this._emotionRecognitionReady.asObservable();
  readonly isRecording = this._isRecording.asObservable();
  readonly recordingSettings = this._recordingSettings.asObservable();
  readonly cachedFiles = this._cachedFiles.asObservable();

  constructor() { }

  async changeMuseReady(readyBoolean: boolean) {
    if (readyBoolean === this._museReady.value) return false;
    this._museReady.next(readyBoolean);
    this.changeReadyToRecord(readyBoolean);
    console.log('muse ready:', readyBoolean);
    return true;
  }

  async changeConsoleAppReady(readyBoolean: boolean) {
    if (readyBoolean === this._consoleAppReady.value) return false;
    this._consoleAppReady.next(readyBoolean);
    this.changeReadyToRecord(readyBoolean);
    console.log('console app ready', readyBoolean);
    return true;
  }

  async changeEmotionRecognitionReady(camBoolean: boolean) {
    if (camBoolean === this._emotionRecognitionReady.value) return false;
    this._emotionRecognitionReady.next(camBoolean);
    this.changeReadyToRecord(camBoolean);
    console.log('emotion recognition ready', camBoolean);
    return true;
  }

  async changeReadyToRecord(recordBoolean: boolean) {
    // this.onRecordingSettingsChange();
    if (!this.isValidRecordingChange()) {
      this.invalidRecordingState();
      return false;
    };
    if (recordBoolean === this._canStartRecording.value) return false;
    console.log('record ready', recordBoolean);
    this._canStartRecording.next(recordBoolean);
    return true;
  }

  private isValidRecordingChange() {
    const settings = this._recordingSettings.value;
    if (settings.eyeTracking && !this._consoleAppReady.value) return false;
    if (settings.faceRecording && !this._emotionRecognitionReady.value) return false;
    if (settings.muse && !this._museReady.value) return false;
    return true;
  }

  private invalidRecordingState() {
    if (this._isRecording.value) this.changeIsRecording(false);
  }

  async changeIsRecording(recordingBoolean: boolean) {
    console.log('isRecording', recordingBoolean);
    if (!this._canStartRecording.value) return false;
    this._isRecording.next(recordingBoolean);
    return true;
  }

  async changeUserSettings(settings: Partial<RecordingUserConfig>) {
    this.invalidRecordingState();
    const newSettings = { ...this._recordingSettings.value, ...settings };
    this._recordingSettings.next(newSettings);
    return true;
  }

  async addCachedFile(file: CachedFile) {
    // we have to filter it out
    const oldCache = this._cachedFiles.value.filter(f => f.flag !== file.flag);
    this._cachedFiles.next([...oldCache, file]);
    return true;
  }

  async changeCachedFiles(files: CachedFile[]) {
    // check for duplicate flags
    const noDuplicates = [];
    for (const file of files) {
      if (noDuplicates.find(f => f.flag === file.flag)) continue;
      noDuplicates.push(file);
    }
    this._cachedFiles.next(noDuplicates);
    return true;
  }

  /// get cached file observable, useful if you only want to subscribe to one file
  getCachedFile(flag: FileDataType) {
    return this.cachedFiles.pipe(
      map(files => files.find(f => f.flag === flag)),
      distinctUntilChanged((prev, curr) => prev?.file === curr?.file)
    );
  }

  consoleAppRequired() {
    const settings = this._recordingSettings.value;
    return settings.eyeTracking || settings.faceRecording || settings.mouseAndKeyboard;
  }

}
