<!-- <section class="time-series" [ngClass]="{ 'loading': !amplitudes }">
  <article class="time-series-channel" *ngFor="let canvas of canvases, let i = index">
      <aside class="time-series-control">
          <div class="time-series-number"
              [ngStyle]="{ 'background': colors[i].borderColor }">
              {{ channelNames[i] }}
          </div>
      </aside>
      <canvas class="time-series-canvas"></canvas>
      <aside class="time-series-amplitude"
          [ngStyle]="{ 'background': colors[i].borderColor }">
          {{ uVrms[i].toFixed(1) }} uVrms
      </aside>
  </article>
</section> -->
<section class="time-series">
    <article class="time-series-channel" *ngFor="let chart of charts, let i = index">
        <aside class="time-series-control">
            <div class="time-series-number"
            [ngStyle]="{ 'background': colors[i].borderColor }">
                {{channelNames[i]}}
            </div>
        </aside>
        <canvas class="time-series-canvas"></canvas>
        <aside class="time-series-amplitude"
          [ngStyle]="{ 'background': colors[i].borderColor }">
          uVrms
        </aside>
    </article>
</section>