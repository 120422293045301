import { RecordingComponent } from './recording/recording.component';
import { TimestampConverterComponent } from './timestamp-converter/timestamp-converter.component';
import { CleanDataComponent } from './clean-data/clean-data.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PresentDataComponent } from "./present-data/present-data.component";

const routes: Routes = [
  /* { path: '', redirectTo: '/recording', pathMatch: 'full' },
   { path: 'recording', component:  AppModule },
  { path: 'editing', component:  TimestampConverterComponent } */
  {
    path: '',
    redirectTo: '/recording',
    pathMatch: 'full'
  },
  { path: 'recording', component: RecordingComponent },
  { path: 'converting', component: TimestampConverterComponent },
  { path: 'clean-data', component: CleanDataComponent },
  { path: 'present-data', component: PresentDataComponent },
  { path: '**', redirectTo: '/recording', pathMatch: 'full' }
];

export const appRouting = RouterModule.forRoot(routes);

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
