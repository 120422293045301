import { Injectable } from '@angular/core';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { BehaviorSubject } from 'rxjs';

// scoped service for providing video data for children of video components
@Injectable({ providedIn: null })
export class VideoService {
  private _api = new BehaviorSubject<VgApiService | null>(null);

  readonly api = this._api.asObservable();

  getApi() {
    return this._api.value;
  }

  setApi(api: VgApiService) {
    this._api.next(api);
  }

}
