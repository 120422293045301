import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import debounce from 'debounce';
import GazeHandler, { ConnectionStatus, WebSocketResponse, WebSocketResponseAction } from './gaze-api';
import { CommunicationService } from '../services/communication.service';
import { RecordingUserConfig } from '../shared/types/user-config';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-console-app-connector',
  templateUrl: './console-app-connector.component.html',
  styleUrls: ['./console-app-connector.component.scss'],
  providers: [GazeHandler]
})
export class AppConsoleConnector implements OnInit, OnDestroy {
  connectionStatus: ConnectionStatus = ConnectionStatus.NotConnected;
  calibrationStatus: string;

  userSettings: RecordingUserConfig;

  private gaze?: GazeHandler;
  private subscription: Subscription;

  private _isRecording = false;
  @Input()
  get isRecording(): boolean {
    return this._isRecording;
  }
  set isRecording(isRecording: boolean) {

    this._isRecording = isRecording;
    if (this.gaze !== undefined) {
      if (isRecording) {
        this.gaze.StartLogging();
      }
      else {
        this.gaze.EndLogging();
      }
    }
  }

  private _sessionName = '';
  get sessionName(): string {
    return this._sessionName;
  }
  // runs asynchronously to prevent blocking the UI
  set sessionName(sessionName: string) {
    new Promise<void>((resolve) => {
      this._sessionName = sessionName;
      this.debouncedSetSessionName(sessionName);
    });

  }

  sessionNameError: string;
  sessionNameSuccess: string;

  private debouncedSetSessionName = debounce(this.DebouncedSetSessionName.bind(this), 200);

  constructor(private comData: CommunicationService, private snackbar: MatSnackBar) {
  }

  ngOnInit() {
    this.subscription = this.comData.recordingSettings.subscribe(userSettings => this.userSettings = userSettings);
  }

  ngOnDestroy() {
    this.comData.changeConsoleAppReady(false);
    this.subscription?.unsubscribe();
    this.subscription = null;

    if (this.gaze !== undefined) {
      this.gaze.Disconnect();
    }
  }

  isGazeDisabled() {
    return !this.userSettings.mouseAndKeyboard && !this.userSettings.eyeTracking;
  }

  isGazeActive() {
    return this.connectionStatus === ConnectionStatus.Connected;
  }

  async connectGaze() {
    this._sessionName = null;
    this.ConnectionStatusCallback(ConnectionStatus.Connecting);
    this.gaze = new GazeHandler(
      this.HandleMessage.bind(this),
      this.ConnectionStatusCallback.bind(this),
      this.userSettings.eyeTracking,
      this.userSettings.mouseAndKeyboard
    );
  }

  Calibrate() {
    if (this.gaze !== undefined) {
      this.gaze.Calibrate();
    }
  }

  async Disconnect() {
    this.sessionName = '';
    this.sessionNameError = '';
    this.sessionNameSuccess = '';
    this.gaze.Disconnect();
    this.comData.changeConsoleAppReady(false);
  }

  private ConnectionStatusCallback(status: ConnectionStatus) {
    this.connectionStatus = status;
    switch (status) {
      case ConnectionStatus.Connected: {
        this.gaze.GetCalibration();
        break;
      }
      case ConnectionStatus.UnableToConnect: {
        this.comData.changeConsoleAppReady(false);
        this.snackbar.open('Unable to connect to console app', 'Dismiss');
        break;
      }
      default: {
        break;
      }
    }
  }

  private async HandleMessage(response: WebSocketResponse) {
    switch (response.Action) {
      case WebSocketResponseAction[WebSocketResponseAction.gotCalibrationStatus]: {
        this.SetCalibrationStatus(response.Data);
        break;
      }
      case WebSocketResponseAction[WebSocketResponseAction.loggingStarted]: {
        console.log('LoggingStarted', response.Data);
        break;
      }
      case WebSocketResponseAction[WebSocketResponseAction.loggingEnded]: {
        console.log('LoggingEnded', response.Data);
        break;
      }
      case WebSocketResponseAction[WebSocketResponseAction.failedStartLogging]: {
        console.error('FailedStartLogging', response.Data);
        this.snackbar.open('Console app failed to start logging', 'Dismiss');
        break;
      }
      case WebSocketResponseAction[WebSocketResponseAction.successfullySetSessionName]: {
        this.SuccessfullySetSessionName();
        break;
      }
      case WebSocketResponseAction[WebSocketResponseAction.failedSettingSessionName]: {
        this.FailedSettingSessionNameHandler(response.Data);
        break;
      }
      case WebSocketResponseAction[WebSocketResponseAction.failedReceivingData]: {
        console.error('FailedReceivingData', response.Data);
        this.snackbar.open('Console app has received invalid data', 'Dismiss');
        break;
      }
      case WebSocketResponseAction[WebSocketResponseAction.handlerNotRunning]: {
        if (!this.userSettings.eyeTracking) break;
        console.error(response.Data);
        this.snackbar.open('Console app\'s MyGaze handler is not running', 'Dismiss');
        break;
      }
      default: {
        console.log(`Received incorrect action: ${String(response.Action)} ${response.Data}`);
        break;
      }
    }
  }

  private SetCalibrationStatus(status: string) {
    this.calibrationStatus = status;
  }

  private async DebouncedSetSessionName(sessionName: string) {
    this.sessionNameError = '';
    if (this.gaze !== undefined) {
      this.gaze.SetSessionName(sessionName);
    }
  }

  private async FailedSettingSessionNameHandler(data: string) {
    this.sessionNameError = data;
    this.sessionNameSuccess = null;
    this.comData.changeConsoleAppReady(false);
  }

  private async SuccessfullySetSessionName() {
    this.sessionNameError = null;
    this.sessionNameSuccess = 'Valid name provided';
    this.comData.changeConsoleAppReady(true);
  }
}
