import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CachedFile, RequestFile } from './request-files';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileDataType } from '../recorded-data-input/recorded-data-enums';
import { CommunicationService } from '../services/communication.service';
import { Subscription } from 'rxjs';

interface LocalCache {
  flag: FileDataType;
  // actual filename may differ from the default filename
  fileName: string;
}

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrl: './file-input.component.scss'
})
export class FileInputComponent implements OnInit, OnDestroy {
  @Input() requestFiles: RequestFile[];

  @Output() fileUpload = new EventEmitter<File>();

  // cached files that are stored in the service, differentiated by the flag
  cachedFiles: LocalCache[] = [];
  subscription: Subscription;

  protected readonly fileReader = new FileReader();

  constructor(private snackbar: MatSnackBar, private comData: CommunicationService) { }

  // check if we can load any cached files on component init
  ngOnInit() {
    this.subscription = this.comData.cachedFiles.subscribe((files) => this.onFileCacheUpdate(files));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscription = null;
  }

  onFileCacheUpdate(files: CachedFile[]) {
    this.cachedFiles = files.map((file) => ({ flag: file.flag, fileName: file.file.name }));
  }

  isFileCached(file: RequestFile) {
    return this.cachedFiles.find((cachedFile) => cachedFile.flag === file.flag);
  }

  async onFileUpload(event: Event, requestFile: RequestFile) {
    if (!event.target || !(event.target instanceof HTMLInputElement)) {
      this.snackbar.open('Error with uploading file', 'Dismiss');
      throw new Error('Event target is not an instance of HTMLInputElement');
    }
    if (!event.target?.files.length) {
      this.snackbarError('No files selected');
      return;
    }
    if (event.target.files.length > 1) {
      this.snackbarError('Multiple file upload not supported');
      return;
    }

    const file = event.target.files[0];
    this.fileUpload.emit(file);
    requestFile.callback?.(file);
    // we can directly compare the none flag since it should not exist with other flags
    if (requestFile.flag !== FileDataType.None) {
      const cachedData: CachedFile = {
        file,
        defaultFileName: requestFile.defaultFileName,
        displayName: requestFile.displayName,
        fileType: requestFile.fileType,
        flag: requestFile.flag
      };
      this.comData.addCachedFile(cachedData);
    };

  }

  snackbarError(error: string, action = 'Dismiss') {
    this.snackbar.open(error, action);
    throw new Error(error);
  }


  trackByDefaultFileName(index: number, file: RequestFile) {
    return file.defaultFileName;
  }
}
