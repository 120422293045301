export class CSVRecordEmotions {
  public timestampColumn: any;
  public angryColumn: any;
  public sadColumn: any;
  public disgustedColumn: any;
  public surprisedColumn: any;
  public happyColumn: any;
  public neutralColumn: any;
}

export class CSVRecordMuse {
  public timeColumn: any;
  public timeAsSeconds: any;

  public deltaTP9Column: any;
  public deltaAF7Column: any;
  public deltaAF8Column: any;
  public deltaTP10Column: any;

  public thetaTP9Column: any;
  public thetaAF7Column: any;
  public thetaAF8Column: any;
  public thetaTP10Column: any;

  public alphaTP9Column: any;
  public alphaAF7Column: any;
  public alphaAF8Column: any;
  public alphaTP10Column: any;

  public betaTP9Column: any;
  public betaAF7Column: any;
  public betaAF8Column: any;
  public betaTP10Column: any;

  public gammaTP9Column: any;
  public gammaAF7Column: any;
  public gammaAF8Column: any;
  public gammaTP10Column: any;
}

export class CSVProjectData {
  public elapsedTime: any;
  public timeInSeconds: any;
  public recording: any;
  public task: any;
  public event: any;
  public details: any;
  public application: any;
  public owner: any;
  public notes: any;
  public url: any;
  public pageTitle: any;
  public title: any;
  public score: any;
}

export class MuseDataPerSecond {
  public timeAsSeconds: any;

  public deltaTP9: any[] = [];
  public deltaAF7: any[] = [];
  public deltaAF8: any[] = [];
  public deltaTP10: any[] = [];

  public thetaTP9: any[] = [];
  public thetaAF7: any[] = [];
  public thetaAF8: any[] = [];
  public thetaTP10: any[] = [];

  public alphaTP9: any[] = [];
  public alphaAF7: any[] = [];
  public alphaAF8: any[] = [];
  public alphaTP10: any[] = [];

  public betaTP9: any[] = [];
  public betaAF7: any[] = [];
  public betaAF8: any[] = [];
  public betaTP10: any[] = [];

  public gammaTP9: any[] = [];
  public gammaAF7: any[] = [];
  public gammaAF8: any[] = [];
  public gammaTP10: any[] = [];
}

export class MuseAveragesPerSecond {
  public timeAsSeconds: any;

  public deltaTP9: any;
  public deltaAF7: any;
  public deltaAF8: any;
  public deltaTP10: any;

  public thetaTP9: any;
  public thetaAF7: any;
  public thetaAF8: any;
  public thetaTP10: any;

  public alphaTP9: any;
  public alphaAF7: any;
  public alphaAF8: any;
  public alphaTP10: any;

  public betaTP9: any;
  public betaAF7: any;
  public betaAF8: any;
  public betaTP10: any;

  public gammaTP9: any;
  public gammaAF7: any;
  public gammaAF8: any;
  public gammaTP10: any;
}

export class MuseBaselineData {

  public deltaTP9: any[] = [];
  public deltaAF7: any[] = [];
  public deltaAF8: any[] = [];
  public deltaTP10: any[] = [];

  public thetaTP9: any[] = [];
  public thetaAF7: any[] = [];
  public thetaAF8: any[] = [];
  public thetaTP10: any[] = [];

  public alphaTP9: any[] = [];
  public alphaAF7: any[] = [];
  public alphaAF8: any[] = [];
  public alphaTP10: any[] = [];

  public betaTP9: any[] = [];
  public betaAF7: any[] = [];
  public betaAF8: any[] = [];
  public betaTP10: any[] = [];

  public gammaTP9: any[] = [];
  public gammaAF7: any[] = [];
  public gammaAF8: any[] = [];
  public gammaTP10: any[] = [];
}

export class MuseAverages {
  public deltaTP9: any;
  public deltaAF7: any;
  public deltaAF8: any;
  public deltaTP10: any;

  public thetaTP9: any;
  public thetaAF7: any;
  public thetaAF8: any;
  public thetaTP10: any;

  public alphaTP9: any;
  public alphaAF7: any;
  public alphaAF8: any;
  public alphaTP10: any;

  public betaTP9: any;
  public betaAF7: any;
  public betaAF8: any;
  public betaTP10: any;

  public gammaTP9: any;
  public gammaAF7: any;
  public gammaAF8: any;
  public gammaTP10: any;
}

export class AnalyzedMuseDataPerSecond {
  public timeAsSeconds: any;

  public attentionDeltaTP9: any;
  public attentionDeltaAF7: any;
  public attentionDeltaAF8: any;
  public attentionDeltaTP10: any;
  public attentionAverages: any;

  public arousalGammaTP9: any;
  public arousalGammaAF7: any;
  public arousalGammaAF8: any;
  public arousalGammaTP10: any;
  public arousalAverages: any;

  public avoid: any;
  public approach: any;

}

export class ColumnAveragesPerTask {
  public averageOfAttentionAverages: any;
  public averageOfArousalAverages: any;
  public avoidAverages: any;
  public approachAverages: any;
}

export class Task {
  public taskNumber: number;
  public secondBasedData: AnalyzedMuseDataPerSecond[];
  public averages: ColumnAveragesPerTask;
}

export class CSVRecordEmotionsAnalyzed {
  public timestampColumn: any;
  public timeStampsAsSecondsColumn: any;
  public angryColumn: any;
  public sadColumn: any;
  public disgustedColumn: any;
  public surprisedColumn: any;
  public happyColumn: any;
  public neutralColumn: any;
  public strongestEmotionColumn: any;
  public secondStrongestEmotionColumn: any;
  public thirdStrongestEmotionColumn: any;
}

export class CSVRecordMouseMovement {
  public timeColumn: any;
  public xColumn: any;
  public yColumn: any;
}

export class CSVRecordMouseEvent {
  public timeColumn: any;
  public buttonNameColumn: any;
  public stateColumn: any;
  public eventShown = false;
}

export class CSVRecordEyeTrackingEvent {
  public timeColumn: any;
  public LeftEyeXColumn: any;
  public LeftEyeYColumn: any;
  public RightEyeXColumn: any;
  public RightEyeYColumn: any;

  public eventShown = false;
}
